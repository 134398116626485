import React from 'react';

import GraficoPerguntaExtra from '../GraficoPerguntaExtra';
import * as S from './styles';

interface IDashboardPerguntaExtraCardProps {
  perguntaExtra?: string;
  percentuaisPerguntaExtra: number[];
}

export function DashboardPerguntaExtraCard({
  perguntaExtra,
  percentuaisPerguntaExtra,
}: IDashboardPerguntaExtraCardProps) {
  return (
    <S.Container>
      <S.Title>Pergunta extra</S.Title>
      <S.Grafico>
        {perguntaExtra && <strong>{perguntaExtra}</strong>}
        <GraficoPerguntaExtra values={percentuaisPerguntaExtra} />
      </S.Grafico>
    </S.Container>
  );
}
