import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled(Form)``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  width: 100%;
`;
