import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useToast } from '../../hooks/toast';

import getValidationError from '../../utils/getValidationsError';
import logo from '../../assets/logoBranca.png';
import Input from '../../components/Input';
import { Button } from '../../components/Button';

import { Container, Content, Background } from './styles';
import api from '../../services/api';

interface Data {
  email: string;
  password: string;
  token: string;
  passwordConfirmation: string;
}

export function ForgotPassword() {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const [resetingPassword, setResetingPassword] = useState(false);

  const handleSubmit = useCallback(
    async (data: Data) => {
      try {
        formRef.current?.setErrors({});

        if (!resetingPassword) {
          const schema = Yup.object().shape({
            email: Yup.string()
              .required('E-mail obrigatório!')
              .email('Informe um e-mail em um formato válido!'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          await api.get(`/session/password?email=${data.email}`);

          setResetingPassword(true);

          return;
        }

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório!')
            .email('Informe um e-mail em um formato válido!'),
          password: Yup.string().required('Informe a nova senha.'),
          token: Yup.string().required('Informe o token recebido.'),

          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'As senhas informadas não conferem.',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/session/password', {
          ...data,
        });

        addToast({
          title: 'Sucesso!',
          description: 'Senha alterada com sucesso.',
        });

        window.location.replace('/login');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Ops!',
              description: erro,
            });
          });
        }
      }
    },
    [addToast, resetingPassword],
  );

  return (
    <Container>
      <Content>
        <img src={logo} alt="Tua Opinião" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Redefinir sua senha</h1>
          <Input name="email" placeholder="E-mail" autoComplete="username" />

          {resetingPassword && (
            <>
              <Input name="token" placeholder="Token" />
              <Input name="password" type="password" placeholder="Senha" />
              <Input
                name="passwordConfirmation"
                type="password"
                placeholder="Confirmação de senha"
              />
            </>
          )}

          <Button type="submit" color="white">
            {!resetingPassword ? 'Solicitar código' : 'Confirmar'}
          </Button>
          <a href="/login">Voltar ao login</a>
        </Form>
      </Content>
      <Background />
    </Container>
  );
}
