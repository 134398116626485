import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationError from '../../utils/getValidationsError';
import logo from '../../assets/logoBranca.png';
import Input from '../../components/Input';
import { Button } from '../../components/Button';

import { Container, Content, Background } from './styles';

interface Data {
  email: string;
  password: string;
}

export function Login() {
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: Data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório!')
            .email('Informe um e-mail em um formato válido!'),
          password: Yup.string().required('Senha obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ email: data.email, password: data.password }).catch(
          (err) => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação!',
              description: err.response.data.message,
            });
          },
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação!',
              description: erro,
            });
          });
        }
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <Content>
        <img src={logo} alt="Tua Opinião" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça seu login</h1>
          <Input name="email" placeholder="E-mail" autoComplete="username" />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            autoComplete="current-password"
          />
          <Button type="submit" color="white">
            Entrar
          </Button>
          <a href="/forgot">Esqueci minha senha</a>
        </Form>
      </Content>
      <Background />
    </Container>
  );
}
