import React from 'react';

import * as S from './styles';

interface IDashboardCardrepostasProps {
  respostasBoas: Array<any>;
  respostasNeutras: Array<any>;
  respostasRuins: Array<any>;
  segundaPergunta?: string;
}

export function DashboardCardRespostas({
  respostasBoas,
  respostasNeutras,
  respostasRuins,
  segundaPergunta,
}: IDashboardCardrepostasProps) {
  return (
    <S.Container>
      <S.Title>Principais respostas</S.Title>

      <S.Respostas>
        {segundaPergunta && <strong>{segundaPergunta}</strong>}
        {respostasBoas &&
          respostasBoas.map((item) => (
            <S.Row key={item.id}>
              <S.Icon>
                <S.IconSmile size={35} />
              </S.Icon>

              <S.Resposta>
                <p>{item.avaliacao}</p>
              </S.Resposta>
            </S.Row>
          ))}
        {respostasNeutras &&
          respostasNeutras.map((item) => (
            <S.Row key={item.id}>
              <S.Icon>
                <S.IconMeh size={35} />
              </S.Icon>

              <S.Resposta>
                <p>{item.avaliacao}</p>
              </S.Resposta>
            </S.Row>
          ))}
        {respostasRuins &&
          respostasRuins.map((item) => (
            <S.Row key={item.id}>
              <S.Icon>
                <S.IconFrown size={35} />
              </S.Icon>
              <S.Resposta>
                <p>{item.avaliacao}</p>
              </S.Resposta>
            </S.Row>
          ))}
      </S.Respostas>
    </S.Container>
  );
}
