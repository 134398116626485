import React from 'react';

import Chart from 'react-apexcharts';
// import { Container } from './styles';

interface FeelingScore {
  id: string;
  feeling: string;
  score: string;
  survey_scores_id: string;
}
interface GraficoNlpProps {
  feelingsScores: FeelingScore[];
}

export function GraficoNlp({ feelingsScores }: GraficoNlpProps) {
  if (!feelingsScores || feelingsScores.length === 0) {
    return <></>;
  }

  const negativeFeelings = ['Frustração', 'Raiva', 'Tristeza', 'Medo', 'Desgosto'];

  return (
    <Chart
      options={{
        chart: {
          animations: {
            enabled: true,
          },
          background: '#fff',
          foreColor: '#373D3F',
          fontFamily: 'Roboto',
          height: 421,
          id: 'wsVIj',
          toolbar: {
            show: false,
          },
          type: 'bar',
          width: 527,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 100,
            fontSize: '16px',
            colors: ["#304758"]
          },
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
        },
        grid: {
          strokeDashArray: 3,
          padding: {
            right: 25,
            left: 15,
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          shared: false,
          intersect: true,
        },
        xaxis: {
          // categories: feelingsScores.map((item) => item.feeling),
          offsetY: 3,

          labels: {
            trim: true,
            style: {
              fontSize: '14px',
              fontFamily: 'Roboto',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: { fontSize: '14px',
            fontFamily: 'Roboto', },
          },
          title: {
            style: {
              fontWeight: 700,
              fontSize: '24px',
              fontFamily: 'Roboto',
            },
          },
        },
      }}
      series={[
        {
          name: 'Sentimento',
          data: feelingsScores
            .sort((a, b) => Number(b.score) - Number(a.score))
            .map((item) => {
              return {
                x: item.feeling,
                y: Number(item.score),
                fillColor: item.feeling === 'Neutro' ?
                  '#f5cf1b' : negativeFeelings.includes(item.feeling) ? '#c62e24' : '#8ad052',
              };
            }),
        },
      ]}
      type="bar"
      width={850}
    />
  );
}
