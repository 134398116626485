import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import getValidationError from '../../utils/getValidationsError';
import { Checkbox } from '../Checkbox';
import Input from '../Input';
import { InputSelectForm } from '../InputSelectForm';

import api from '../../services/api';
import { Button } from '../Button';
import { ModalTitle } from '../ModalTitle';
import TextArea from '../TextArea';
import { Container, Row } from './styles';

interface InputSelectOption {
  label: string;
  value: string;
}

interface CadastroPesquisaModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  userUnidades: InputSelectOption[] | undefined;
  userTemplates: InputSelectOption[] | undefined;
  userIdSelected: string;
}

export function CadastroPesquisaModal({
  isOpen,
  onRequestClose,
  userTemplates,
  userUnidades,
  userIdSelected,
}: CadastroPesquisaModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [analisarAvaliacoes, setAnalisarAvaliacoes] = useState(false);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome da pesquisa é obrigatório!'),
          apresentacaoEmpresa: Yup.string().required(
            'A apresentação da empresa é obrigatório!',
          ),
          dtEncerramento: Yup.date().min(
            new Date(),
            'A data de encerramento não pode ser menor que o dia atual!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Atenção!',
              description: erro,
            });
          });
        }
      }

      await api
        .post('/admin/pesquisas', {
          ...data,
          user_id: userIdSelected,
          analisarAvaliacoes,
        })
        .then(() => {
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Pesquisa salva com sucesso',
          });

          formRef.current?.reset();
        })
        .catch((err) => {
          addToast({
            description: err.response.data.message,
            title: 'Erro!',
            type: 'error',
          });
        });
    },
    [userIdSelected, analisarAvaliacoes, addToast],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Nova pesquisa" />

        <Row>
          <Input name="nome" placeholder="Nome" />
          <Input name="apresentacaoEmpresa" placeholder="Apresentação" />
        </Row>

        <TextArea name="segundaPergunta" placeholder="Segunda pergunta" />
        <TextArea name="perguntaExtra" placeholder="Pergunta extra" />

        <Row>
          <Input name="numeroTwilio" placeholder="Número do twilio" />
          <Input
            name="periodoCarencia"
            placeholder="Tempo de carência"
            type="number"
          />
        </Row>

        <InputSelectForm
          name="template_id"
          placeholder="Selecione um template"
          options={userTemplates}
        />
        <InputSelectForm
          name="unidade_id"
          placeholder="Selecione uma unidade"
          options={userUnidades}
        />

        <Row>
          <Checkbox
            checked={analisarAvaliacoes}
            onClick={() => setAnalisarAvaliacoes(!analisarAvaliacoes)}
            label="Analisar Avaliações"
          />

          <Input
            name="dtEncerramento"
            placeholder="Data de encerramento"
            type="date"
          />
        </Row>

        <Input name="npsQuestion" placeholder="Pergunta NPS site" />
        <Input name="secondQuestion" placeholder="Segunda pergunta site" />
        <Input name="thirdQuestion" placeholder="Terceira pergunta site" />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
