import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { Container } from './styles';

export function Loader() {
  return (
    <Container>
      <ClipLoader color={'#292c52'} size={150} />
    </Container>
  );
}
