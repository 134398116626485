import crypto from 'crypto';

export function decryptData(payload: string) {
  try {
    const resizedIV = Buffer.allocUnsafe(16);
    const iv = crypto
      .createHash('sha256')
      .update('TuaOpiniaoIvSecret')
      .digest();

    iv.copy(resizedIV);

    const key = crypto
      .createHash('sha256')
      .update('TuaOpiniaoHashSecret')
      .digest();
    const decipher = crypto.createDecipheriv('aes256', key, resizedIV);
    const msg = [];

    msg.push(decipher.update(payload, 'hex', 'binary'));
    msg.push(decipher.final('binary'));

    return msg.join('');
  } catch (error) {
    return payload;
  }
}
