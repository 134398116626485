import React, { useCallback, useEffect, useState } from 'react';

import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { CadastroCasoModal } from '../../../components/CadastroCasoModal';
import Charts from '../../../components/Charts';
import { EdicaoCasoModal } from '../../../components/EdicaoCasoModal';
import { FiltroPesquisaModal } from '../../../components/FiltroPesquisaModal';
import { PageContainer } from '../../../components/PageContainer';
import { PDFViewerModal } from '../../../components/PDFViewerModal';
import { Tabela } from './TabelaCasos';

import { ICasoDto } from '../../../dtos/ICasoDto';
import { useAuth } from '../../../hooks/auth';
import { useLoader } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import Axios from 'axios';
import { Button } from '../../../components/Button';
import { InputSelect } from '../../../components/InputSelect';
import { IOptionSelectDto } from '../../../dtos/IOptionSelectDto';
import { decryptData } from '../../../utils/decryptData';
import {
  Card,
  Content,
  DivInputSelect,
  Header,
  Row,
  Sugestoes,
} from './styles';

export function Casos() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { showLoader, hideLoader } = useLoader();

  const [pesquisasOptions, setPesquisasOptions] = useState<
    IOptionSelectDto[]
  >();
  const [usersOptions, setUsersOptions] = useState<IOptionSelectDto[]>();

  const [audioUrl, setAudioUrl] = useState<string>();
  const [audioExtraUrl, setAudioExtraUrl] = useState<string>();
  const [dsSugestaoInteracao, setDsSugestaoInteracao] = useState('');
  const [casoSelected, setCasoSeleceted] = useState<ICasoDto>({} as ICasoDto);
  const [casos, setCasos] = useState<ICasoDto[]>([]);

  const [isOpenCadastroCasoModal, setIsOpenCadastroCasoModal] = useState(false);
  const [isOpenEdicaoCasoModal, setIsOpenEdicaoCasoModal] = useState(false);
  const [isOpenFiltroPesquisaModal, setIsOpenFiltroPesquisaModal] = useState(
    false,
  );
  const [isOpenPDFModalViewer, setIsOpenPDFViewer] = useState(false);
  const [pesquisaSelected, setPesquisaSelected] = useState(
    {} as IOptionSelectDto,
  );
  const [filtro1, setFiltro1] = useState<IOptionSelectDto[]>();
  const [filtro2, setFiltro2] = useState<IOptionSelectDto[]>();
  const [filtro3, setFiltro3] = useState<IOptionSelectDto[]>();
  const [filtro4, setFiltro4] = useState<IOptionSelectDto[]>();
  const [filtro5, setFiltro5] = useState<IOptionSelectDto[]>();

  useEffect(() => {
    setUsersOptions(user.users);
  }, [user]);

  async function handleSelectUser(value: any) {
    showLoader();

    setCasos([]);
    setAudioUrl('');
    setCasoSeleceted({} as ICasoDto);

    api
      .get(`/admin/pesquisas?userId=${value.value}`)
      .then((response) => {
        if (response.data) {
          setPesquisasOptions(
            response.data.map((pesquisa: any) => ({
              value: pesquisa.id,
              label: pesquisa.nome,
              perguntaExtra: pesquisa.perguntaExtra,
              segundaPergunta: pesquisa.segundaPergunta,
            })),
          );
        }
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: err.response.data.message,
        });
      });

    hideLoader();
  }

  async function handleVerInteracao(row: ICasoDto) {
    showLoader();
    setAudioUrl('');

    await api
      .get(`/interacao?id=${row.id}`)
      .then((response) => {
        if (row.nota <= 6) {
          const { arraySugestoes, sugestaoFinal } = response.data.responseMl;

          setCasoSeleceted({
            ...row,
            dsSugestao: sugestaoFinal,
            sugestoes: arraySugestoes,
          });

          setDsSugestaoInteracao(sugestaoFinal);
          setAudioUrl(row.audioUrl);
          setAudioExtraUrl(row.audioExtraUrl);

          return;
        }

        setAudioUrl(row.audioUrl);
        setAudioExtraUrl(row.audioExtraUrl);
        setCasoSeleceted({ ...row });
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: err.response.data.message,
        });
      });

    hideLoader();
  }

  async function handleSelectPesquisa(value: any) {
    showLoader();

    await api.get(`/admin/casos?pesquisaId=${value.value}`).then((response) => {
      setCasos(response.data);
    });

    const response = await api.get(`/admin/filtros?pesquisaId=${value.value}`);

    setFiltro1(response.data.filtros1);
    setFiltro2(response.data.filtros2);
    setFiltro3(response.data.filtros3);
    setFiltro4(response.data.filtros4);
    setFiltro5(response.data.filtros5);

    setPesquisaSelected(value);
    setCasoSeleceted({} as ICasoDto);

    hideLoader();
  }

  async function handleExportNaoRespondentes() {
    try {
      showLoader();
      const response = await api.get(
        `/admin/casos?pesquisaId=${pesquisaSelected.value}&naoRespondentes=true`,
      );

      if (!response.data) {
        addToast({
          title: 'Ops!',
          description: 'Nenhum dado encontrado!',
          type: 'info',
        });
        return;
      }

      const xlsxData: any[] = [];

      response.data.forEach((item: any) => {
        xlsxData.push({
          telefone: decryptData(item.telefone),
          nome: item.nome,
          pesquisa: item.nomePesquisa,
          data: new Intl.DateTimeFormat('pt-BR').format(
            new Date(item.created_at),
          ),
          filtro1: item.filtro1,
          filtro2: item.filtro2,
          filtro3: item.filtro3,
          filtro4: item.filtro4,
          filtro5: item.filtro5,
        });
      });

      const ws = XLSX.utils.json_to_sheet(xlsxData);
      const wb = {
        Sheets: { data: ws },
        SheetNames: ['data'],
      };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });

      FileSaver.saveAs(data, pesquisaSelected.label + '_nao_respondentes.xlsx');
    } catch (err) {
      console.log(err);

      addToast({
        title: 'Erro!',
        description: 'Erro ao gerar relatorio!',
        type: 'error',
      });
    } finally {
      hideLoader();
    }
  }

  async function handleExportAllData() {
    try {
      showLoader();
      const response = await api.get(
        `/admin/casos?pesquisaId=${pesquisaSelected.value}&all=true`,
      );

      if (!response.data) {
        addToast({
          title: 'Ops!',
          description: 'Nenhum dado encontrado!',
          type: 'info',
        });
        return;
      }

      const xlsxData: any[] = [];

      response.data.forEach((item: any) => {
        xlsxData.push({
          pesquisa: pesquisaSelected.label,
          data: new Intl.DateTimeFormat('pt-BR').format(
            new Date(item.created_at),
          ),
          nome: item.nome,
          telefone: decryptData(item.telefone),
          nota: item.nota,
          avaliacao: item.avaliacao,
          respostaExtra: item.respostaExtra,
          filtro1: item.filtro1,
          filtro2: item.filtro2,
          filtro3: item.filtro3,
          filtro4: item.filtro4,
          filtro5: item.filtro5,
        });
      });

      const ws = XLSX.utils.json_to_sheet(xlsxData);
      const wb = {
        Sheets: { data: ws },
        SheetNames: ['data'],
      };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });

      FileSaver.saveAs(
        data,
        pesquisaSelected.label +
        '_' +
        new Date()
          .toLocaleDateString()
          .replace('/', '-')
          .replace('/', '-')
          .replace('/', '-') +
        '.xlsx',
      );
    } catch (err) {
      console.log(err);

      addToast({
        title: 'Erro!',
        description: 'Erro ao gerar relatorio!',
        type: 'error',
      });
    } finally {
      hideLoader();
    }
  }

  function handleOpenCadastroCasoModal() {
    if (!pesquisaSelected) {
      addToast({
        description: 'Selecione uma pesquisa!',
        title: 'Erro!',
        type: 'error',
      });

      return;
    }

    setIsOpenCadastroCasoModal(true);
  }

  function handleOpenCadastroCasoModalAsEditing() {
    if (!casoSelected?.id) {
      addToast({
        description: 'Selecione um caso!',
        title: 'Erro!',
        type: 'error',
      });

      return;
    }

    setIsOpenEdicaoCasoModal(true);
  }

  function handleRequestCloseCadastroCasoModal() {
    setIsOpenCadastroCasoModal(false);

    handleSelectPesquisa(pesquisaSelected);
  }

  function handleRequestCloseEdicaoCasoModal() {
    setIsOpenEdicaoCasoModal(false);

    handleSelectPesquisa(pesquisaSelected);
  }

  async function handleFiltrarDadosPesquisa(
    filtros: Array<string | undefined>,
  ) {
    await api
      .get(
        `/admin/casos/filtros?pesquisaId=${pesquisaSelected.value}&filtro1=${filtros[0]}&filtro2=${filtros[1]}&filtro3=${filtros[2]}&filtro4=${filtros[3]}&filtro5=${filtros[4]}`,
      )
      .then((response) => {
        setCasos(response.data);
      })
      .catch((err) => {
        addToast({
          type: 'error',
          description: err.response.data.message,
          title: 'Atenção!',
        });
      });

    handleRequestCloseFiltroPesquisaModal();
  }

  function handleRequestCloseFiltroPesquisaModal() {
    setIsOpenFiltroPesquisaModal(false);
  }

  function handleOpenPdfModalViewer() {
    if (
      (filtro1 && filtro1.length > 0) ||
      (filtro2 && filtro2.length > 0) ||
      (filtro3 && filtro3.length > 0) ||
      (filtro4 && filtro4.length > 0) ||
      (filtro5 && filtro5.length > 0)
    ) {
      if (
        ((filtro1 && filtro1.length > 0) ||
          (filtro2 && filtro2.length > 0) ||
          (filtro3 && filtro3.length > 0) ||
          (filtro4 && filtro4.length > 0) ||
          (filtro5 && filtro5.length > 0)) &&
        casos.length < 300
      ) {
        setIsOpenPDFViewer(true);
      } else {
        addToast({
          description: 'Filtre os dados para fazer a impressão do relatório. ',
          title: 'Aviso.',
          type: 'info',
        });
      }
    }
    setIsOpenPDFViewer(true);

    return;
  }

  function handleClosePDFViewerModal() {
    setIsOpenPDFViewer(false);
  }

  const handleSendSurveyToNlpService = useCallback(async () => {
    const response = await Axios.post(
      'https://tuaopiniao-nlp-service-007bf59f4d0c.herokuapp.com/submit-survey-for-review',
      {
        surveyId: pesquisaSelected.value,
        phrases: casos
          .filter((item) => item.avaliacao !== null && item.avaliacao !== '')
          .map((item) => ({
            id: item.id,
            phrase: item.avaliacao,
          })),
      },
    );

    addToast({
      description: response.data.message,
      title: 'Sucesso.',
    });
  }, [pesquisaSelected, casos, addToast]);

  return (
    <PageContainer title="Selecione o Usuário">
      <Header>
        <InputSelect
          options={usersOptions}
          placeholder="Selecione um usuário"
          onChange={(value) => handleSelectUser(value)}
        />
      </Header>
      <Card>
        <Row>
          <DivInputSelect>
            <InputSelect
              name="pesquisa"
              placeholder="Selecione uma pesquisa"
              onChange={(value) => handleSelectPesquisa(value)}
              options={pesquisasOptions}
              value={pesquisaSelected}
            />
          </DivInputSelect>

          {((filtro1 && filtro1.length > 0) ||
            (filtro2 && filtro2.length > 0) ||
            (filtro3 && filtro3.length > 0) ||
            (filtro4 && filtro4.length > 0) ||
            (filtro5 && filtro5.length > 0)) && (
              <Button onClick={() => setIsOpenFiltroPesquisaModal(true)}>
                Filtros da pesquisa
              </Button>
            )}
        </Row>
        <Tabela rows={casos} handleVerInteracao={handleVerInteracao} />

        {pesquisaSelected?.value && (
          <Row>
            <Button onClick={handleOpenCadastroCasoModal}>Cadastrar</Button>
            <Button onClick={handleOpenCadastroCasoModalAsEditing}>
              Editar
            </Button>
            <Button onClick={handleOpenPdfModalViewer}>Exportar dados</Button>

            <Button onClick={handleExportNaoRespondentes}>
              Exportar não respondentes
            </Button>
            <Button onClick={handleExportAllData}>
              Exportar todos os dados
            </Button>

            <Button onClick={handleSendSurveyToNlpService}>
              Gerar analise de sentimento
            </Button>
          </Row>
        )}

        {casoSelected?.avaliacao ? (
          <Content>
            <h1>Resposta do cliente</h1>
            <h2>Nota {casoSelected?.nota}</h2>

            <h3>{pesquisaSelected?.segundaPergunta}</h3>

            {audioUrl && audioUrl !== '' ? (
              <audio controls>
                <source src={audioUrl} type="audio/ogg" />
              </audio>
            ) : null}

            {
              !!casoSelected?.avaliacao && (
                <textarea
                  maxLength={60000}
                  readOnly
                  value={casoSelected?.avaliacao}
                />
              )
            }


            <h3>{pesquisaSelected?.perguntaExtra}</h3>



            {audioExtraUrl && audioExtraUrl !== '' ? (
              <audio controls>
                <source src={audioExtraUrl} type="audio/ogg" />
              </audio>
            ) : null}


            {
              !!casoSelected?.respostaExtra && (
                <textarea
                  maxLength={60000}
                  readOnly
                  value={casoSelected?.respostaExtra}
                />
              )
            }



            {casoSelected?.nota && casoSelected?.nota <= 7 ? (
              <Sugestoes>
                {casoSelected?.sugestoes &&
                  casoSelected?.sugestoes.length !== 0 ? (
                  <div>
                    <h1>Sugestão de interação</h1>

                    <Charts value={casoSelected?.sugestoes} />

                    <h3>Editar mensagem</h3>
                    <textarea
                      defaultValue={casoSelected?.dsSugestao}
                      value={dsSugestaoInteracao}
                      onChange={(event) =>
                        setDsSugestaoInteracao(event.currentTarget.value)
                      }
                    />

                    <Button
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send?phone=${casoSelected?.nome
                          }&text=
                          ${dsSugestaoInteracao
                            .split(' ')
                            .join('%20')
                            .split('\n')
                            .join('%20')}`,
                          '_blank',
                        );
                      }}
                    >
                      Enviar WhatsApp
                    </Button>
                  </div>
                ) : null}
              </Sugestoes>
            ) : null}
          </Content>
        ) : null}
      </Card>

      <CadastroCasoModal
        onRequestClose={handleRequestCloseCadastroCasoModal}
        isOpen={isOpenCadastroCasoModal}
        pesquisaIdSelected={pesquisaSelected.value}
      />

      <EdicaoCasoModal
        onRequestClose={handleRequestCloseEdicaoCasoModal}
        isOpen={isOpenEdicaoCasoModal}
        caso={casoSelected}
      />

      <FiltroPesquisaModal
        isOpen={isOpenFiltroPesquisaModal}
        handleBuscarDados={handleFiltrarDadosPesquisa}
        filtro1={filtro1}
        filtro2={filtro2}
        filtro3={filtro3}
        filtro4={filtro4}
        filtro5={filtro5}
        onRequestClose={handleRequestCloseFiltroPesquisaModal}
      />

      <PDFViewerModal
        onCloseModal={handleClosePDFViewerModal}
        isOpen={isOpenPDFModalViewer}
        casos={casos}
        pesquisa={{
          nome: pesquisaSelected?.label,
          perguntaExtra: pesquisaSelected?.perguntaExtra,
        }}
      />
    </PageContainer>
  );
}
