import React, { useEffect, useRef } from 'react';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';

import { useField } from '@unform/core';
import { Container } from './styles';

interface Props extends InputMaskProps {
  name: string;
  autoComplete?: string;
  mask: string;
}

const InputMask: React.FC<Props> = ({ name, mask, ...rest }, ref) => {
  const inputElementRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputElementRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <ReactInputMask
        ref={inputElementRef}
        mask={mask}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
};

export default InputMask;
