import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}
const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user }: any = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (user && user.admin) {
          return isPrivate === !!user ? (
            <Component />
          ) : (
            <Redirect
              to={{ pathname: isPrivate ? '/login' : '/admin/dashboard' }}
            />
          );
        }
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{ pathname: isPrivate ? '/login' : '/user/dashboard' }}
          />
        );
      }}
    />
  );
};
export default Route;
