import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Login } from '../pages/Login';
import Sidebar from '../components/Sidebar';
import AdminSidebar from '../components/AdminSidebar';
import Route from './Route';
import { useAuth } from '../hooks/auth';
import { ForgotPassword } from '../pages/ForgotPassword';

export function Routes() {
  const { user } = useAuth();
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot" component={ForgotPassword} />
      <Route path="/admin/" component={AdminSidebar} isPrivate />
      <Route path="/user/" component={Sidebar} isPrivate />
      <Redirect
        from="/"
        to={user && !user.admin ? '/user/dashboard' : '/admin/dashboard'}
      />
    </Switch>
  );
}
