import React from 'react';

import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import * as S from './styles';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onClick(value: boolean): void;
}

export function Checkbox({ label, checked, onClick }: CheckboxProps) {
  function handleChangeState() {
    onClick(!checked);
  }

  return (
    <S.Container onClick={handleChangeState}>
      <MaterialCheckbox checked={checked} onChange={handleChangeState} />
      <label>{label}</label>
    </S.Container>
  );
}
