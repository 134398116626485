import styled from 'styled-components';

export const Header = styled.div`
  width: 50%;

  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;

  width: 100%;

  p {
    margin-left: 5px;
  }
`;

export const DataGerado = styled.h4`
  /* margin: 40px; */
  color: var(--text);

  font-size: 2rem;
`;

export const Card = styled.div`
  display: flex;

  flex-direction: column;

  width: 120px;
  height: 120px;

  margin: 10px;
  border-radius: 10px;

  padding: 8px;

  align-items: center;
  justify-content: space-around;

  background-color: var(--background);

  span,
  p,
  strong {
    text-align: center;
    color: var(--text);
  }
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  border-radius: 10px;

  margin-top: 20px;
  padding: 8px;

  align-items: center;
  justify-content: center;

  background-color: var(--grey);
`;
