/*eslint no-template-curly-in-string: "off"*/
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';

import { PageContainer } from '../../components/PageContainer';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import InputMask from '../../components/InputMask';
import { InputSelect } from '../../components/InputSelect';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';
import { useLoader } from '../../hooks/loading';
import api from '../../services/api';
import getValidationError from '../../utils/getValidationsError';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import { Button } from '../../components/Button';
import Input from '../../components/Input';
import { IPesquisaDto } from '../../dtos/IPesquisaDto';
import * as S from './styles';

export function SendSurveyPage() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { showLoader, hideLoader } = useLoader();


  const formRef = useRef<FormHandles>(null);

  const [surveys, setSurveys] = useState<
    Array<IOptionSelectDto>
  >([]);

  const [selectedSurvey, setSelectedSurvey] = useState<IOptionSelectDto>();

  const [survey, setSurvey] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState<IOptionSelectDto>();

  const [unitsOptions, setUnitsOptions] = useState<IOptionSelectDto[]>();

  useEffect(() => {
    showLoader();

    api.get(`/unidades?userId=${user.id}`).then((response) => {
      setUnitsOptions(
        response.data.map((pesquisa: any) => ({
          value: pesquisa.id,
          label: pesquisa.nome,
        })),
      );
    }).finally(hideLoader);

  }, [user, showLoader, hideLoader]);


  async function handleChangeUnit(value: IOptionSelectDto) {
    if (value) {
      api.get(`/pesquisas/from-unidade?unidadeId=${value.value}`).then((response) => {
        setSurveys(
          response.data.map((pesquisa: any) => ({
            value: pesquisa.id,
            label: pesquisa.nome,

          })),
        );
      });
    } else {
      setSurveys([])
      setSelectedSurvey({} as IOptionSelectDto)
    }

    setSelectedUnit(value);
  }

  async function handleChangeSurvey(value: IOptionSelectDto) {
    if (value) {
      setSelectedSurvey(value);

      await api
      .get<IPesquisaDto>(`/pesquisas/by-id?pesquisaId=${value.value}`)
      .then((response) => {

        setSurvey(response.data);
      });
    }
  }

  const handleSubmit = useCallback(
    async (data) => {

      if (!selectedSurvey || !selectedSurvey.value) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: 'Selecione uma pesquisa!',
        });

        return;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome da pesquisa é obrigatório!'),
          telefone: Yup.string().min(15).required('Informe o telefone no formato (99) 99999-9999'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Atenção!',
              description: erro,
            });
          });
        }

        return
      }


      await api.post('/pesquisas/send-survey', {
        pesquisaId: selectedSurvey.value,
        nome: data.nome, telefone: data.telefone,

      }).catch((err) => {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: 'Não foi possível realizar o envio da pesquisa. Verifique os dados e tente novamente!',
        });
      })

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Pesquisa enviada com sucesso!',
      });

      hideLoader()

      formRef.current?.reset();

    },
    [selectedSurvey, hideLoader, addToast],
  );

  return (
    <PageContainer title="Enviar contatos">
      <S.Header>

        <InputSelect
          options={unitsOptions}
          onChange={(value) => handleChangeUnit(value as IOptionSelectDto)}
          placeholder="Selecione uma unidade"
          value={selectedUnit}
        />

        <InputSelect
          placeholder="Selecione uma pesquisa"
          onChange={(value) => handleChangeSurvey(value as IOptionSelectDto)}
          options={surveys}
        />
      </S.Header>
      <S.Content>
        <S.FormContainer>
          <h2> Enviar pesquisa </h2>

          <S.Card>
            <Form ref={formRef} onSubmit={handleSubmit}>

            <S.Row>
              <Input name="nome" placeholder="Nome" />
              <InputMask
                name="telefone"
                placeholder="Telefone"
                mask="(99) 99999-9999"
              />

            </S.Row>

            <Button type="submit">Enviar pesquisa</Button>

            </Form>
          </S.Card>


          {
            !!survey && !!survey.template && (
              <S.Card>
                <br />
                <br />
                <h2>Perguntas:</h2>

                <p><strong>Primeira pergunta:</strong> {survey.template.descricao.replaceAll('${empresa}', survey.apresentacaoEmpresa)}</p>
                 <br />
                <p><strong>Segunda pergunta:</strong> {survey.segundaPergunta}</p>
                <br />
                <p><strong>Terceira pergunta:</strong> {survey.perguntaExtra}</p>

              </S.Card>
            )
          }


        </S.FormContainer>
      </S.Content>
    </PageContainer>
  );
}
