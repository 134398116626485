import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../../../components/Button';
import { CadastroFiltroModal } from '../../../components/CadastroFiltroModal';
import { PageContainer } from '../../../components/PageContainer';
import { useAuth } from '../../../hooks/auth';
import { useLoader } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import { Tabela } from './TabelaFiltros';

import api from '../../../services/api';

import { EditarFiltroModal } from '../../../components/EditarFiltroModal';
import { InputSelect } from '../../../components/InputSelect';
import { IFiltroDto } from '../../../dtos/IFiltroDto';
import { IOptionSelectDto } from '../../../dtos/IOptionSelectDto';
import { Content, Header, TableContainer } from './styles';

export function Filtros() {
  const { user } = useAuth();
  const { addToast } = useToast();

  const { showLoader, hideLoader } = useLoader();
  const [filtros, setFiltros] = useState<IFiltroDto[]>([]);
  const [filtro, setFiltro] = useState({} as IFiltroDto);

  const [usersOptions, setUsersOptions] = useState<IOptionSelectDto[]>([]);

  const [pesquisaSelected, setPesquisaSelected] = useState({
    label: 'Selecione uma pesquisa...',
    value: '',
  });
  const [userSelected, setUserSelected] = useState({
    label: 'Selecione um usuário...',
    value: '',
  });

  const [pesquisasOptions, setPesquisasOptions] = useState<IOptionSelectDto[]>(
    [],
  );
  const [isOpenCadastroFiltroModal, setIsOpenCadastroPesquisaModal] = useState(
    false,
  );
  const [isOpenEditarFiltroModal, setIsOpenEditarPesquisaModal] = useState(
    false,
  );

  useEffect(() => {
    setUsersOptions(user.users);
  }, [user]);

  const handleSelectUser = useCallback(
    async (value: any) => {
      showLoader();

      setPesquisaSelected({
        label: 'Selecione uma pesquisa...',
        value: '',
      });

      setUserSelected(value);

      await api
        .get(`/admin/pesquisas?userId=${value.value}`)
        .then((response) => {
          if (response.data) {
            setPesquisasOptions(
              response.data.map((pesquisa: any) => ({
                value: pesquisa.id,
                label: pesquisa.nome,
              })),
            );
          }
        })
        .catch((err) => {
          addToast({
            type: 'error',
            title: 'Erro!',
            description: err.response.data.message,
          });
        })
        .finally(hideLoader);
    },
    [showLoader, hideLoader, addToast],
  );

  async function handleDelete(arraySelected: string[]) {
    try {
      await api.delete(`/admin/templates?id=${arraySelected[0]}`);

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Deletado com sucesso!',
      });

      await handleSelectPesquisa(pesquisaSelected);
    } catch (err) {
      addToast({
        title: 'Atenção!',
        description:
          'Esse template está associado a alguma pesquisa e não pode ser excluido!',
      });
    }
  }

  async function handleEdit(arraySelected: string[]) {
    const response = await api.get(`/admin/filtros?id=${arraySelected[0]}`);

    setFiltro(response.data);
    setIsOpenEditarPesquisaModal(true);
  }
  async function handleSelectPesquisa(value: any) {
    showLoader();

    const response = await api.get(`/admin/filtros/${value.value}`);
    setFiltros(response.data);
    setPesquisaSelected(value);
    hideLoader();
  }

  function handleOpenCadastroFiltroModal() {
    if (!pesquisaSelected.value) {
      addToast({
        type: 'info',
        description: 'Selecione a pesquisa antes de cadastrar o filtro!',
        title: 'Aviso!',
      });

      return;
    }
    setIsOpenCadastroPesquisaModal(true);
  }

  function handleRequestCloseModal() {
    setIsOpenCadastroPesquisaModal(false);

    handleSelectPesquisa(pesquisaSelected);
  }

  function handleRequestCloseEditarModal() {
    setIsOpenEditarPesquisaModal(false);

    handleSelectPesquisa(pesquisaSelected);
  }

  return (
    <PageContainer title="Filtros">
      <Header>
        <InputSelect
          options={usersOptions}
          placeholder="Selecione um usuário"
          onChange={(value) => handleSelectUser(value)}
          value={userSelected}
        />
        <InputSelect
          onChange={(value) => handleSelectPesquisa(value)}
          placeholder="Selecione uma pesquisa"
          options={pesquisasOptions}
          value={pesquisaSelected}
        />
      </Header>
      <Content>
        <TableContainer>
          <Tabela
            rows={filtros}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
          <Button onClick={handleOpenCadastroFiltroModal}>Novo Filtro</Button>
        </TableContainer>
      </Content>

      <CadastroFiltroModal
        isOpen={isOpenCadastroFiltroModal}
        onRequestClose={handleRequestCloseModal}
        pesquisaIdSelected={pesquisaSelected.value}
      />

      <EditarFiltroModal
        isOpen={isOpenEditarFiltroModal}
        onRequestClose={handleRequestCloseEditarModal}
        filtro={filtro}
      />
    </PageContainer>
  );
}
