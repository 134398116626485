import React, { useState } from 'react';
import Modal from 'react-modal';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { useLoader } from '../../hooks/loading';
import closeImage from '../../assets/close.svg';

import { NpsPDFDocument } from '../NpsPDFDocument/NpsPDFDocument';

import { Container, Row } from './styles';
import { Checkbox } from '../Checkbox';
import { ICasoDto } from '../../dtos/ICasoDto';
import { Button } from '../Button';

interface PDFViewerModalProps {
  isOpen: boolean;
  onCloseModal(): void;
  casos: ICasoDto[];
  pesquisa?: {
    nome: string;
    perguntaExtra?: string;
  };
}

export interface NPS {
  quantidadePromotores: number | 0;
  quantidadeDetratores: number | 0;
  quantidadeNeutros: number | 0;
  percentPromotores: number | 0;
  percentDetratores: number | 0;
  percentNeutros: number | 0;
}

export function PDFViewerModal({
  isOpen,
  onCloseModal,
  casos,
  pesquisa,
}: PDFViewerModalProps) {
  const [incluirNome, setIncluirNome] = useState(true);
  const [incluirContato, setIncluirContato] = useState(true);
  const [incluirAvaliacao, setIncluirAvaliacao] = useState(true);
  const [incluirRespostaExtra, setIncluirRespostaExtra] = useState(true);
  const [incluirPromotores, setIncluirPromotores] = useState(true);
  const [incluirDetratores, setIncluirDetratores] = useState(true);
  const [incluirNeutros, setIncluirNeutros] = useState(true);
  const [casosFiltrados, setCasosFiltrados] = useState<ICasoDto[]>(casos);
  const [nps, setNps] = useState<NPS>({} as NPS);
  const { showLoader, hideLoader } = useLoader();
  const [showPDF, setShowPDF] = useState(false);

  function handleSubmitForm() {
    showLoader();
    const promiseAll = new Promise((resolve, reject) => {
      var casosList: ICasoDto[] = [];

      setCasosFiltrados([]);
      setNps({} as NPS);

      let quantidadeDetratores = 0;
      let quantidadeNeutros = 0;
      let quantidadePromotores = 0;

      casos.forEach((caso) => {
        if (caso.nota < 7) {
          if (incluirDetratores) {
            casosList.push(caso);
          }
          quantidadeDetratores += 1;
        } else if (caso.nota >= 7 && caso.nota <= 8) {
          if (incluirNeutros) {
            casosList.push(caso);
          }
          quantidadeNeutros += 1;
        } else if (caso.nota >= 9) {
          if (incluirPromotores) {
            casosList.push(caso);
          }
          quantidadePromotores += 1;
        }
      });

      var percentDetratores = (100 * quantidadeDetratores) / casos.length;
      var percentNeutros = (100 * quantidadeNeutros) / casos.length;
      var percentPromotores = (100 * quantidadePromotores) / casos.length;

      resolve({
        percentDetratores,
        percentNeutros,
        percentPromotores,
        quantidadePromotores,
        quantidadeDetratores,
        quantidadeNeutros,
        casosList,
      });
    });

    promiseAll
      .then((response: any) => {
        setNps({ ...response });

        setCasosFiltrados(response.casosList);
      })
      .finally(hideLoader);

    setShowPDF(true);
  }

  function handleRequestClose() {
    setShowPDF(false);

    setIncluirAvaliacao(true);
    setIncluirContato(true);
    setIncluirNome(true);
    setIncluirRespostaExtra(true);
    setIncluirDetratores(true);
    setIncluirNeutros(true);
    setIncluirPromotores(true);

    onCloseModal();
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>
      <Container onSubmit={handleSubmitForm}>
        <Row>
          <Checkbox
            label="Incluir nome"
            checked={incluirNome}
            onClick={setIncluirNome}
          />

          <Checkbox
            label="Incluir contato"
            checked={incluirContato}
            onClick={setIncluirContato}
          />
        </Row>
        <Row>
          <Checkbox
            label="Incluir Avaliacao"
            checked={incluirAvaliacao}
            onClick={setIncluirAvaliacao}
          />

          <Checkbox
            label="Incluir Resposta Extra"
            checked={incluirRespostaExtra}
            onClick={setIncluirRespostaExtra}
          />
        </Row>
        <Row>
          <Checkbox
            label="Incluir Promotores"
            checked={incluirPromotores}
            onClick={setIncluirPromotores}
          />

          <Checkbox
            label="Incluir Detratores"
            checked={incluirDetratores}
            onClick={setIncluirDetratores}
          />

          <Checkbox
            label="Incluir Neutros"
            checked={incluirNeutros}
            onClick={setIncluirNeutros}
          />
        </Row>

        <Button onClick={handleSubmitForm}>Gerar PDF</Button>

        {showPDF && (
          <div onClick={handleRequestClose}>
            <PDFDownloadLink
              document={
                <NpsPDFDocument
                  casos={casosFiltrados}
                  pesquisa={pesquisa}
                  incluirContato={incluirContato}
                  incluirNome={incluirNome}
                  incluirAvaliacao={incluirAvaliacao}
                  incluirRespostaExtra={incluirRespostaExtra}
                  closeLoader={hideLoader}
                  nps={nps}
                />
              }
              fileName={`${pesquisa?.nome + '.pdf'}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Carregando documento' : 'Baixar documento!'
              }
            </PDFDownloadLink>
          </div>
        )}
      </Container>
    </Modal>
  );
}
