import styled from 'styled-components';

export const Container = styled.form``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-bottom: 8px;

  width: 100%;
`;
