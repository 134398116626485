import { FormHandles } from '@unform/core';
import React, { useRef, useState } from 'react';
import Modal from 'react-modal';

import getValidationError from '../../utils/getValidationsError';
import closeImage from '../../assets/close.svg';
import { InputSelectForm } from '../InputSelectForm';
import { useToast } from '../../hooks/toast';
import * as Yup from 'yup';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import Input from '../Input';

import api from '../../services/api';

import * as S from './styles';
import { ModalTitle } from '../ModalTitle';
import { IUsuarioDto } from '../../dtos/IUsusarioDto';
import InputMask from '../InputMask';

interface InputSelectOption {
  label: string;
  value: string;
}

interface IEditarUsuarioModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  planos: InputSelectOption[] | undefined;
  usuario: IUsuarioDto;
}

export function EditarUsuarioModal({
  isOpen,
  onRequestClose,
  planos,
  usuario,
}: IEditarUsuarioModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [ativo, setAtivo] = useState(usuario.ativo);
  const [admin, setAdmin] = useState(usuario.admin);

  const handleSubmit = async (data: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório!'),
        email: Yup.string()
          .required('E-mail obrigatório!')
          .email('Informe um e-mail em um formato válido!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api
        .put(`/admin/users?id=${usuario.id}`, { ...data, ativo, admin })
        .then(() => {
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Usuário atualizado com sucesso',
          });

          formRef.current?.reset();

          onRequestClose();
        })
        .catch((err) => {
          addToast({
            type: 'error',
            title: 'Erro ao salvar usuário!',
            description: err.response.data.message,
          });
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationError(err);
        formRef.current?.setErrors(error);

        err.errors.forEach((erro) => {
          addToast({
            type: 'error',
            title: 'Erro ao salvar usuário!',
            description: erro,
          });
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <S.Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Editar" />
        <Input
          name="nome"
          placeholder="Nome da empresa"
          defaultValue={usuario.nome}
        />
        <Input name="email" placeholder="E-mail" defaultValue={usuario.email} />
        <InputMask
          name="whatsapp"
          placeholder="WhatsApp"
          mask="5599999999999"
          defaultValue={usuario.whatsapp}
        />

        <Input name="password" type="password" placeholder="Senha" />
        <InputSelectForm
          name="planoId"
          placeholder="Selecione um plano"
          options={planos}
          defaultValue={planos?.find(
            (plano) => plano.value === usuario.planoId,
          )}
        />
        <S.Row>
          <Checkbox
            label="Admin"
            checked={admin || usuario.admin}
            onClick={setAdmin}
          />
          <Checkbox
            label="Ativo"
            checked={ativo || usuario.ativo}
            onClick={setAtivo}
          />
        </S.Row>
        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </S.Container>
    </Modal>
  );
}
