import React, { useRef, useEffect } from 'react';

import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';

import { useField } from '@unform/core';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  placeholder: string;
}

export function InputSelectForm({
  options = [],
  placeholder,
  name,
  ...rest
}: Props) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  const customStyles = {
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      minWidth: '200px',
      // color: '#828282',
    }),
    control: () => ({
      minWidth: '200px',
      display: 'flex',
      backgroundColor: '#FFF',
      borderRadius: '10px',
      border: '1px solid #d6d6d6',
      padding: 8,
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ReactSelect
      defaultValue={defaultValue}
      ref={selectRef}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      {...rest}
    />
  );
}
