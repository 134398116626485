import { FormHandles } from '@unform/core';
import React, { useRef } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import getValidationError from '../../utils/getValidationsError';

import api from '../../services/api';
import { Button } from '../Button';
import Input from '../Input';
import InputMask from '../InputMask';
import { ModalTitle } from '../ModalTitle';
import TextArea from '../TextArea';
import { Container } from './styles';

interface CadastroCasoModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  pesquisaIdSelected: string;
}

export function CadastroCasoModal({
  isOpen,
  onRequestClose,
  pesquisaIdSelected,
}: CadastroCasoModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(data: any) {
    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('O nome da pesquisa do contato.'),
        telefone: Yup.string().required('Informe o telefone.'),
        nota: Yup.string().required('Informe a nota.'),
        avaliacao: Yup.string().required('Informe a avaliação.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationError(err);
        formRef.current?.setErrors(error);

        err.errors.forEach((erro) => {
          addToast({
            type: 'error',
            title: 'Atenção!',
            description: erro,
          });
        });
      }
    }

    await api
      .post('/admin/casos', { ...data, pesquisaId: pesquisaIdSelected })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro ao salvar os dados!',
          description: err.response.data.message,
        });
      });

    addToast({
      type: 'success',
      title: 'Sucesso!',
      description: 'Caso salvo com sucesso',
    });

    formRef.current?.reset();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Novo Caso" />

        <Input name="nome" placeholder="Nome" />
        <InputMask
          name="telefone"
          placeholder="Telefone"
          mask="(99) 99999-9999"
        />
        <InputMask name="nota" placeholder="Nota" mask="99" />
        <TextArea name="avaliacao" placeholder="Avaliação" rows={5} />
        <TextArea name="respostaExtra" placeholder="Pergunta Extra" rows={5} />
        <Input name="filtro1" placeholder="Filtro 1" />
        <Input name="filtro2" placeholder="Filtro 2" />
        <Input name="filtro3" placeholder="Filtro 3" />
        <Input name="filtro4" placeholder="Filtro 4" />
        <Input name="filtro5" placeholder="Filtro 5" />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
