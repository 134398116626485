import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Button } from '../../../components/Button';
import { CadastroPesquisaModal } from '../../../components/CadastroPesquisaModal';
import { Checkbox } from '../../../components/Checkbox';
import { EditarPesquisaModal } from '../../../components/EditarPesquisaModal';
import FileInput from '../../../components/FileInput';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import { InputSelect } from '../../../components/InputSelect';
import { PageContainer } from '../../../components/PageContainer';
import { IContatoDto } from '../../../dtos/IContatoDto';
import { IOptionSelectDto } from '../../../dtos/IOptionSelectDto';
import { IPesquisaDto } from '../../../dtos/IPesquisaDto';
import getValidationError from '../../../utils/getValidationsError';
import { TabelaContatos } from './TabelaContatos';
import { Tabela } from './TabelaPesquisas';

import { useAuth } from '../../../hooks/auth';
import { useLoader } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/api';

import Axios from 'axios';
import * as S from './styles';


interface ContactsCoprel {
  dt_ativacao:string;
  codpessoa:number;
  nome_razaosocial:string;
  fone01: string;
  email:string;
  cidade:string;
}

export function Pesquisas() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const formRefContato = useRef<FormHandles>(null);
  const sendMailForm = useRef<FormHandles>(null);
  const { showLoader, hideLoader } = useLoader();

  const [incluirNonoDigito, setIncluirNonoDigito] = useState(false);
  const [validarNumero, setValidarNumero] = useState(true);
  const [useCooperandoAccount, setUseCooperandoAccount] = useState(false);
  const [useTuaOpiniaoDevAccount, setUseTuaOpiniaoDevAccount] = useState(false);

  const [pesquisa, setPesquisa] = useState<IPesquisaDto>();
  const [pesquisas, setPesquisas] = useState<IPesquisaDto[]>([]);
  const [contatos, setContatos] = useState<IContatoDto[]>([]);
  const [usersOptions, setUsersOptions] = useState<IOptionSelectDto[]>([]);

  const [userIdSelected, setUserIdSelected] = useState('');

  const [userTemplates, setUserTemplates] = useState<IOptionSelectDto[]>();
  const [userUnidades, setUserUnidades] = useState<IOptionSelectDto[]>();

  // const [, setPreContatosPesquisa] = useState<IPreContatosPesquisaDto[]>([]);

  const [
    isOpenCadastroPesquisaModal,
    setIsOpenCadastroPesquisaModal,
  ] = useState(false);

  const [isOpenEditarPesquisaModal, setIsOpenEditarPesquisaModal] = useState(
    false,
  );

  useEffect(() => {
    setUsersOptions(user.users);
  }, [user]);

  async function handleEditar(arraySelected: string[]) {
    if (arraySelected) {
      await api
        .get<IPesquisaDto>(`/admin/pesquisas?id=${arraySelected[0]}`)
        .then((response) => {
          setPesquisa(response.data);

          handleOpenEditarPesquisaModal();
        });
    }
  }

  async function handleSelectPesquisa(arraySelected: string[]) {
    if (arraySelected) {
      await api
        .get<IPesquisaDto>(`/admin/pesquisas?id=${arraySelected[0]}`)
        .then((response) => {
          setPesquisa(response.data);
        });
    }
  }

  async function handleSelectUser(value: any) {
    showLoader();

    setUserIdSelected(value.value);

    api
      .get<IPesquisaDto[]>(`/admin/pesquisas?userId=${value.value}`)
      .then((response) => {
        setPesquisas(response.data);
      });

    api.get(`/admin/unidades?userId=${value.value}`).then((response) => {
      setUserUnidades(
        response.data.map((unidade: any) => ({
          label: unidade.nome,
          value: unidade.id,
        })),
      );
    });

    api.get(`/admin/templates?userId=${value.value}`).then((response) => {
      setUserTemplates(
        response.data.map((template: any) => ({
          label: template.nome + ' - ' + template.descricao,
          value: template.id,
        })),
      );
    });

    hideLoader();
  }

  async function handleAddContato(data: IContatoDto) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('O nome do é obrigatório!'),
        telefone: Yup.string().required('A telefone é obrigatório!'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setContatos((state) => [...state, data]);

      formRef.current?.reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationError(err);
        formRef.current?.setErrors(error);

        err.errors.forEach((erro) => {
          addToast({
            type: 'error',
            title: 'Erro ao salvar pesquisa!',
            description: erro,
          });
        });

        return;
      }
    }
  }

  async function handleDispararPesquisa() {
    showLoader();

    await api
      .post('/admin/disparopesquisa', {
        pesquisa_id: pesquisa?.id,
        contatos: contatos,
        nonoDigito: incluirNonoDigito,
        validarNumero,
        sendResearchFrom: useCooperandoAccount
          ? 'cooperando'
          : useTuaOpiniaoDevAccount
          ? 'tuaopiniao-dev'
          : 'tuaopiniao',
      })
      .then((response) => {
        alert(
          `Total para envio: ${contatos.length} \nTotal enviados: ${response.data.totalEnviados} \nTotal em carencia: ${response.data.totalNaoEnviados}`,
        );

        window.location.reload();
      })
      .finally(() => hideLoader());
  }

  const handleDispararPesquisaEmail = useCallback(
    async (data) => {
      showLoader();

      await api
        .post('/admin/disparopesquisa/email', {
          pesquisa_id: pesquisa?.id,
          contatos: contatos,
          nonoDigito: incluirNonoDigito,
          validarNumero,
          sendResearchFromMail:
            data.sendMailFrom && data.sendMailFrom !== ''
              ? data.sendMailFrom
              : 'pesquisa@tuaopiniaopesquisas.com.br',
          webCollectUrl:
            data.webCollectUrl && data.webCollectUrl !== ''
              ? data.webCollectUrl
              : 'https://pesquisas.tuaopiniao.com.br',
        })
        .then((response) => {
          alert(
            `Total para envio: ${contatos.length} \nTotal enviados: ${response.data.totalEnviados} \nTotal em carencia: ${response.data.totalNaoEnviados}`,
          );

          window.location.reload();
        })
        .finally(() => hideLoader());
    },
    [
      pesquisa,
      contatos,
      incluirNonoDigito,
      validarNumero,
      hideLoader,
      showLoader,
    ],
  );

  const handleDelete = useCallback(
    (arraySelected: Array<IContatoDto>) => {
      for (const index of arraySelected) {
        setContatos(contatos.filter((contato) => contato !== index));
      }
    },
    [contatos],
  );

  async function handleImportarContatos(data: any) {
    try {
      if (!data.file) {
        addToast({
          type: 'error',
          description: 'Nenhum arquivo importado!',
          title: 'Erro!',
        });

        return;
      }
      const formData = new FormData();
      formData.append('file', data.file);

      const response = await api.post('/admin/contatos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { contatos } = response.data;
      setContatos(contatos);
    } catch (error) {
      addToast({
        type: 'error',
        description: 'Erro ao importar dados, tente novamente!',
        title: 'Erro!',
      });
    }
  }

  function handleOpenCadastroPesquisaModal() {
    if (!userIdSelected) {
      addToast({
        type: 'info',
        description: 'Selecione um usuário primeiro!',
        title: 'Aviso',
      });

      return;
    }

    setIsOpenCadastroPesquisaModal(true);
  }

  function handleCloseCadastroPesquisaModal() {
    setIsOpenCadastroPesquisaModal(false);
  }

  function handleOpenEditarPesquisaModal() {
    if (!pesquisa) {
      addToast({
        type: 'info',
        description: 'Selecione uma pesquisa primeiro!',
        title: 'Aviso',
      });

      return;
    }

    setIsOpenEditarPesquisaModal(true);
  }

  function handleCloseEditarPesquisaModal() {
    setIsOpenEditarPesquisaModal(false);

    window.location.reload();
  }

  // function handleBuscarPreContatosPesquisa() {
  //   api
  //     .get(`/admin/pesquisas/pre-contatos/${pesquisa?.id}`)
  //     .then((response) => setPreContatosPesquisa(response.data));
  // }


  async function handleGetContactsCoprel(){

    showLoader();

    const {data} = await Axios.get('https://api.copreltelecom.com.br/api4/auth/tuaopiniao/DfT5kjh897Ft98Jjfi87023FHTainuxynu')
    const {TOKEN} = data;

    const response = await Axios.get<ContactsCoprel[]>(`https://api.copreltelecom.com.br/api4/semparametrojson/customer/ativacoes-dia-anterior/${TOKEN}`)

    if (response.data && response.data.length > 0) {

      const contatosCoprel: IContatoDto[] = response.data.map((contato) => {

        const nome = contato.nome_razaosocial.charAt(0).toUpperCase()
         + contato.nome_razaosocial.slice(1).toLowerCase()

        return {
          nome: nome,
          telefone: contato.fone01,
          email: contato.email,
          filtro1: contato.codpessoa.toString(),
          filtro2: contato.cidade,
          filtro3: '',
          filtro4: '',
          filtro5: '',
        };
      });

      setContatos(contatosCoprel);

    }


    hideLoader()
  }

  function addTestContacts() {
    const testContats = [
      {
        nome: 'Verônica',
        telefone: '54999071191',
        email: 'mariana@tuaopiniao.com.br',
        filtro1: '',
        filtro2: '',
        filtro3: '',
        filtro4: '',
        filtro5: '',
      },
      {
        nome: 'Augusto',
        telefone: '54996295083',
        email: 'augustokukimusic@gmail.com',
        filtro1: '',
        filtro2: '',
        filtro3: '',
        filtro4: '',
        filtro5: '',
      },
      {
        nome: 'Kenny',
        telefone: '54984436083',
        email: 'bassokenny@gmail.com',
        filtro1: '',
        filtro2: '',
        filtro3: '',
        filtro4: '',
        filtro5: '',
      },
      {
        nome: 'Vitor',
        telefone: '54981141000',
        email: 'vitordallacorte@gmail.com',
        filtro1: '',
        filtro2: '',
        filtro3: '',
        filtro4: '',
        filtro5: '',
      },
    ];

    setContatos((state) => [...testContats, ...state]);
  }

  return (
    <PageContainer title="Pesquisas">
      <S.Header>
        <InputSelect
          options={usersOptions}
          placeholder="Selecione um usuário"
          onChange={(value) => handleSelectUser(value)}
        />
      </S.Header>

      <S.Content>
        <S.Table>
          <Tabela
            rows={pesquisas}
            handleSelectPesquisa={handleSelectPesquisa}
            handleEditar={handleEditar}
          />

          <Button onClick={handleOpenCadastroPesquisaModal}>
            Nova Pesquisa
          </Button>
        </S.Table>

        {pesquisa && (
          <>
            <S.FormContainer>
              <h2> Enviar pesquisa </h2>

              <div>
                <Form ref={formRef} onSubmit={handleAddContato}>
                  <Input name="nome" placeholder="Nome" />
                  <InputMask
                    name="telefone"
                    placeholder="Telefone"
                    mask="(99) 99999-9999"
                  />
                  <Input name="email" placeholder="E-mail" />

                  <Button type="submit">Adicionar a lista</Button>
                </Form>
                <Form ref={formRefContato} onSubmit={handleImportarContatos}>
                  <S.Row>
                    <FileInput name="file" />
                    <Button type="submit">Importar contatos</Button>
                  </S.Row>
                  {/* <Button onClick={handleBuscarPreContatosPesquisa}>
                    Buscar pré-contatos
                  </Button> */}

                  <Button onClick={handleGetContactsCoprel}>
                    Buscar contatos Nova Ligação TELECOM (COPREL)
                  </Button>
                </Form>
                <Checkbox
                  checked={validarNumero}
                  label="Validar números"
                  onClick={setValidarNumero}
                />
                <Checkbox
                  label="Incluir digito 9"
                  checked={incluirNonoDigito}
                  onClick={setIncluirNonoDigito}
                />
                <Checkbox
                  label="Usar conta Cooperando"
                  checked={useCooperandoAccount}
                  onClick={setUseCooperandoAccount}
                />
                <Checkbox
                  label="Usar conta Tua Opinião Dev"
                  checked={useTuaOpiniaoDevAccount}
                  onClick={setUseTuaOpiniaoDevAccount}
                />
                <Button onClick={addTestContacts}>
                  Clique aqui para facilitar a sua vida (teste)
                </Button>
              </div>
            </S.FormContainer>

            <S.Table>
              <TabelaContatos
                rows={contatos}
                handleSelected={() => {}}
                handleDelete={handleDelete}
              />
            </S.Table>

            <Button onClick={handleDispararPesquisa}>Disparar Pesquisa</Button>

            <br />
            <br />
            <br />
            <br />
            <div>
              <Form ref={sendMailForm} onSubmit={handleDispararPesquisaEmail}>
                <Input name="sendMailFrom" placeholder="E-mail de disparo" />
                <Input name="webCollectUrl" placeholder="Endereço de coleta" />

                <Button type="submit">Disparar Pesquisa por E-mail</Button>
              </Form>
            </div>
          </>
        )}
      </S.Content>

      <CadastroPesquisaModal
        isOpen={isOpenCadastroPesquisaModal}
        onRequestClose={handleCloseCadastroPesquisaModal}
        userTemplates={userTemplates}
        userUnidades={userUnidades}
        userIdSelected={userIdSelected}
      />

      <EditarPesquisaModal
        isOpen={isOpenEditarPesquisaModal}
        onRequestClose={handleCloseEditarPesquisaModal}
        userTemplates={userTemplates}
        userUnidades={userUnidades}
        userIdSelected={userIdSelected}
        pesquisa={pesquisa}
      />
    </PageContainer>
  );
}
