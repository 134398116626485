import React, { useState } from 'react';
import { FormEvent } from 'react';
import Modal from 'react-modal';

import closeImage from '../../assets/close.svg';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';
import { Button } from '../Button';
import { InputSelect } from '../InputSelect';
import { ModalTitle } from '../ModalTitle';
import * as S from './styles';

interface FiltroPesquisaModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  handleBuscarDados(filtros: Array<string | undefined>): Promise<void>;
  filtro1: IOptionSelectDto[] | undefined;
  filtro2: IOptionSelectDto[] | undefined;
  filtro3: IOptionSelectDto[] | undefined;
  filtro4: IOptionSelectDto[] | undefined;
  filtro5: IOptionSelectDto[] | undefined;
}

export function FiltroPesquisaModal({
  isOpen,
  onRequestClose,
  handleBuscarDados,
  filtro1,
  filtro2,
  filtro3,
  filtro4,
  filtro5,
}: FiltroPesquisaModalProps) {
  const [
    selectedFiltro1,
    setSelectedFiltro1,
  ] = useState<IOptionSelectDto | null>();
  const [
    selectedFiltro2,
    setSelectedFiltro2,
  ] = useState<IOptionSelectDto | null>();
  const [
    selectedFiltro3,
    setSelectedFiltro3,
  ] = useState<IOptionSelectDto | null>();
  const [
    selectedFiltro4,
    setSelectedFiltro4,
  ] = useState<IOptionSelectDto | null>();
  const [
    selectedFiltro5,
    setSelectedFiltro5,
  ] = useState<IOptionSelectDto | null>();

  async function handleSubmitForm(event: FormEvent) {
    event.preventDefault();

    const arrayFiltros: Array<string | undefined> = [
      selectedFiltro1?.value,
      selectedFiltro2?.value,
      selectedFiltro3?.value,
      selectedFiltro4?.value,
      selectedFiltro5?.value,
    ];

    await handleBuscarDados(arrayFiltros);

    handleRequestClose();
  }

  function handleRequestClose() {
    setSelectedFiltro1(null);
    setSelectedFiltro2(null);
    setSelectedFiltro3(null);
    setSelectedFiltro4(null);
    setSelectedFiltro5(null);

    onRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={handleRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <S.Container onSubmit={handleSubmitForm}>
        <ModalTitle title="Filtros da pesquisa" />

        <InputSelect
          options={filtro1}
          placeholder="Selecione um valor"
          onChange={(value: any) => setSelectedFiltro1(value)}
          isClearable
        />
        <InputSelect
          options={filtro2}
          placeholder="Selecione um valor"
          onChange={(value: any) => setSelectedFiltro2(value)}
          isClearable
        />
        <InputSelect
          options={filtro3}
          placeholder="Selecione um valor"
          onChange={(value: any) => setSelectedFiltro3(value)}
          isClearable
        />
        <InputSelect
          options={filtro4}
          placeholder="Selecione um valor"
          onChange={(value: any) => setSelectedFiltro4(value)}
          isClearable
        />
        <InputSelect
          options={filtro5}
          placeholder="Selecione um valor"
          onChange={(value: any) => setSelectedFiltro5(value)}
          isClearable
        />
        <S.RowButton>
          <Button type="submit" backgroundColor="white">
            Buscar dados
          </Button>
        </S.RowButton>
      </S.Container>
    </Modal>
  );
}
