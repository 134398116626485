import React from 'react';

import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';

interface Props extends SelectProps<OptionTypeBase> {
  placeholder: string;
}

export function InputSelect({ options = [], placeholder, ...rest }: Props) {
  const customStyles = {
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      minWidth: '200px',
      // color: '#828282',
    }),
    control: () => ({
      minWidth: '200px',
      display: 'flex',
      backgroundColor: '#FFF',
      borderRadius: '10px',
      border: '1px solid #d6d6d6',
      padding: 8,
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return (
    <ReactSelect
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      {...rest}
    />
  );
}
