import React from 'react';

import Chart from 'react-apexcharts';
// import { Container } from './styles';

interface IGraficoEvolucaoNpsProps {
  dados: number[];
  categories: string[];
}

export function GraficoEvolucaoNps({
  categories,
  dados = [],
}: IGraficoEvolucaoNpsProps) {
  return (
    <Chart
      options={{
        chart: {
          type: 'line',
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value: any) => {
            return `${value}%`;
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: undefined,
          },
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories,
          labels: {
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (value: any) => {
              return `${value}%`;
            },
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          },
        },
      }}
      series={[{ name: 'NPS', data: dados }]}
      type="line"
      height="350"
    />
  );
}

export default GraficoEvolucaoNps;
