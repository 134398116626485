import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationError from '../../../utils/getValidationsError';

import api from '../../../services/api';

import { Button } from '../../../components/Button';
import Input from '../../../components/Input';
import { useToast } from '../../../hooks/toast';

import { PageContainer } from '../../../components/PageContainer';
import { TabelaPlanos } from './TabelaPlanos';
import { ContainerFields, Content } from './styles';

interface IPlano {
  id: string;
  nome: string;
  valor: string;
  qtdCasosPesquisas: number;
  tipoPlano: string;
}
export function Planos() {
  const [planos, setPlanos] = useState<IPlano[]>([]);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    api.get('/admin/planos').then((response) => setPlanos(response.data));
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório!'),
          tipoPlano: Yup.string().required('Tipo plano obrigatório!'),
          qtdCasosPesquisas: Yup.number().required(
            'Informe a quantidade de casos!',
          ),
          valor: Yup.number().required('Informe o valor!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post('/admin/planos', data)
          .then(() => {
            addToast({
              type: 'success',
              title: 'Sucesso!',
              description: 'Plano criado com sucesso',
            });

            formRef.current?.reset();
          })
          .catch((err) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar Plano!',
              description: err.response.data.message,
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar Plano!',
              description: erro,
            });
          });
        }
      }
    },
    [addToast],
  );

  return (
    <PageContainer>
      <Content>
        <ContainerFields>
          <h2> Cadastrar novo plano </h2>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="nome" placeholder="Nome do plano" />
            <Input
              name="qtdCasosPesquisas"
              placeholder="Casos por pesquisa"
              type="number"
            />
            <Input name="tipoPlano" placeholder="Tipo plano" />
            <Input name="valor" placeholder="Valor" type="number" />

            <Button type="submit">Salvar</Button>
          </Form>
        </ContainerFields>
      </Content>

      <TabelaPlanos rows={planos} handleDelete={() => {}} />
    </PageContainer>
  );
}
