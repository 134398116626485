import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  align-items: center;

  padding: 10px;
  padding-top: 100px;
  padding-left: 70px;

  min-height: 100vh;
`;

export const Header = styled.div`
  top: 0;

  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 80px;

  background-color: var(--blue);

  img {
    height: 60px;
  }
`;

export const Title = styled.h1`
  color: var(--text);
`;
