import React, { useState, useCallback } from 'react';

import api from '../../../services/api';

import { Tabela } from './TabelaTemplates';

import { PageContainer } from '../../../components/PageContainer';
import { CadastroTemplateModal } from '../../../components/CadastroTemplateModal';
import { InputSelect } from '../../../components/InputSelect';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import * as S from './styles';
import { Button } from '../../../components/Button';
import { EditTemplateModal } from '../../../components/EditTemplateModal';
import { ITemplateDto } from '../../../dtos/ITemplateDto';

interface UserOption {
  label: string;
  value: string;
}

export function Templates() {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [templateSelected, setTemplateSelected] = useState({} as ITemplateDto);
  const [templates, setTemplates] = useState<ITemplateDto[]>([]);

  const [userSelected, setUserSelected] = useState<UserOption>();
  const [isOpenCadastroForm, setIsOpenCadastroForm] = useState(false);
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);

  const handleSelectUser = useCallback(async (value: any) => {
    const response = await api.get(`/admin/templates?userId=${value.value}`);

    setUserSelected(value);
    setTemplates(response.data);
  }, []);

  async function handleDelete(arraySelected: string[]) {
    try {
      await api.delete(`/admin/templates?id=${arraySelected[0]}`);

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Deletado com sucesso!',
      });

      await handleSelectUser(userSelected);
    } catch (err) {
      addToast({
        title: 'Atenção!',
        description:
          'Esse template está associado a alguma pesquisa e não pode ser excluido!',
      });
    }
  }

  async function handleEdit(arraySelected: string[]) {
    const response = await api.get(`/admin/templates?id=${arraySelected[0]}`);

    setTemplateSelected(response.data);
    setIsOpenEditForm(true);
  }

  function handleCloseCadastroModal() {
    setIsOpenCadastroForm(false);

    handleSelectUser(userSelected);
  }

  function handleOpenCadastroModal() {
    setIsOpenCadastroForm(true);
  }

  function handleCloseEditModal() {
    setIsOpenEditForm(false);

    handleSelectUser(userSelected);
  }

  return (
    <PageContainer title="Templates">
      <S.Header>
        <InputSelect
          options={user.users}
          placeholder="Selecione um usuário"
          onChange={(value) => handleSelectUser(value)}
        />
      </S.Header>
      <S.Content>
        <S.Table>
          <Tabela
            rows={templates}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />

          <Button onClick={handleOpenCadastroModal}>Cadastrar</Button>
        </S.Table>
      </S.Content>
      <CadastroTemplateModal
        isOpen={isOpenCadastroForm}
        onRequestClose={handleCloseCadastroModal}
        userSelected={userSelected?.value}
      />
      <EditTemplateModal
        isOpen={isOpenEditForm}
        onRequestClose={handleCloseEditModal}
        template={templateSelected}
      />
    </PageContainer>
  );
}
