import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/Button';
import { PageContainer } from '../../../components/PageContainer';
import { TabelaUsuarios } from './TabelaUsuarios';
import { CadastroUsuarioModal } from '../../../components/CadastrarUsuarioModal';
import { EditarUsuarioModal } from '../../../components/EditarUsuarioModal';
import api from '../../../services/api';

import { Content, Table } from './styles';
import { IUsuarioDto } from '../../../dtos/IUsusarioDto';

export function Usuarios() {
  const [users, setUsers] = useState<IUsuarioDto[]>([]);
  const [planos, setPlanos] = useState<any[]>([]);

  const [isOpenCadastroUsuarioModal, setIsOpenCadastroUsuarioModal] = useState(
    false,
  );
  const [isOpenEditarUsuarioModal, setIsOpenEditarUsuarioModal] = useState(
    false,
  );

  const [user, setUser] = useState<IUsuarioDto>({} as IUsuarioDto);

  useEffect(() => {
    api
      .get<any[]>('/admin/users')
      .then((response) =>
        setUsers(
          response.data.map((element: any) => ({
            ...element,
            plano: element.plano?.nome,
          })),
        ),
      )
      .catch((err) => console.log(err));

    api.get('/admin/planos').then((response) => {
      const planos: any[] = [];

      response.data.forEach((plano: any) => {
        planos.push({ label: plano.nome, value: plano.id });
      });

      setPlanos(planos);
    });
  }, []);

  function handleOpenCadastroUsuarioModal() {
    setIsOpenCadastroUsuarioModal(true);
  }

  async function handleEditar(arraySelected: string[]) {
    if (arraySelected) {
      await api.get(`/admin/users?id=${arraySelected[0]}`).then((response) => {
        setUser(response.data);

        handleOpenEditarUsuarioModal();
      });
    }
  }

  function handleOpenEditarUsuarioModal() {
    setIsOpenEditarUsuarioModal(true);
  }

  function handleCloseEditarUsuarioModal() {
    setIsOpenEditarUsuarioModal(false);

    window.location.reload();
  }
  function handleCloseCadastroUsuarioModal() {
    setIsOpenCadastroUsuarioModal(false);

    // window.location.reload();
  }

  return (
    <PageContainer title="Usuários">
      <Content>
        <Table>
          <TabelaUsuarios rows={users} handleEditar={handleEditar} />
          <Button onClick={handleOpenCadastroUsuarioModal}>Novo usuário</Button>
        </Table>
      </Content>

      <CadastroUsuarioModal
        isOpen={isOpenCadastroUsuarioModal}
        onRequestClose={handleCloseCadastroUsuarioModal}
        planos={planos}
      />

      <EditarUsuarioModal
        isOpen={isOpenEditarUsuarioModal}
        onRequestClose={handleCloseEditarUsuarioModal}
        planos={planos}
        usuario={user}
      />
    </PageContainer>
  );
}
