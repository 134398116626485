import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import getValidationError from '../../utils/getValidationsError';
import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import Input from '../Input';

import api from '../../services/api';
import { Container } from './styles';
import { ModalTitle } from '../ModalTitle';
import { Button } from '../Button';

interface Data {
  nome: string;
}

interface CadastroFiltroModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  pesquisaIdSelected: string;
}

export function CadastroFiltroModal({
  isOpen,
  onRequestClose,
  pesquisaIdSelected,
}: CadastroFiltroModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: Data) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome do filtro!'),
          chave: Yup.string().required('Informe a chave de importação!'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar filtro!',
              description: erro,
            });
          });

          return;
        }
      }

      await api
        .post('/admin/filtros', {
          ...data,
          pesquisa_id: pesquisaIdSelected,
        })

        .catch((err) => {
          addToast({
            type: 'error',
            title: 'Erro ao salvar o filtro!',
            description: err.response.data.message,
          });

          return;
        });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Filtro salvo com sucesso',
      });

      onRequestClose();
    },
    [addToast, onRequestClose, pesquisaIdSelected],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Novo filtro" />

        <Input name="nome" placeholder="Nome" />
        <Input name="chave" placeholder="Chave" />
        <Input name="valores" placeholder="Valores" />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
