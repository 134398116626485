import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  padding: 20px;
  margin-bottom: 5px;
  width: 100%;

  border: 0;
  border-radius: 10px 10px;

  background-color: #deecf2;
`;

export const ContainerFields = styled.div`
  width: 50%;
  margin-top: 20px;

  background: #fff;
  border-radius: 10px;

  padding: 8px;
`;
