import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;

  border-radius: 10px;
  background-color: var(--grey);
`;
export const Table = styled.div`
  padding: 20px;
  width: 100%;
`;
