import React, { createContext, useCallback, useContext, useState } from 'react';
import { Loader } from '../components/Loader';

interface LoaderContextData {
  loading: boolean;
  showLoader(): void;
  hideLoader(): void;
}

const LoaderContext = createContext<LoaderContextData>({} as LoaderContextData);

const LoaderProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = useCallback(() => {
    setLoading(true);
  }, []);
  const hideLoader = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader, loading }}>
      {children}

      {loading && <Loader />}
    </LoaderContext.Provider>
  );
};

function useLoader(): LoaderContextData {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader must be used within a FullPageLoaderProvider');
  }

  return context;
}
export { LoaderProvider, useLoader };
