import React from 'react';
import DashboardCard from '../DashboardCard';

import { Container } from './styles';

interface IDashboardCardsProps {
  quantidadeResposta: number;
  quantidadePromotores: number;
  quantidadeDetratores: number;
  quantidadeNeutros: number;
  percentPromotores: number;
  percentNeutros: number;
  percentDetratores: number;
  percentEngajameto: number;
  totalRead: number;
}

export function DashboardCards({
  quantidadeResposta,
  quantidadePromotores,
  quantidadeDetratores,
  quantidadeNeutros,
  percentDetratores,
  percentNeutros,
  percentPromotores,
  percentEngajameto,
  totalRead,
}: IDashboardCardsProps) {
  return (
    <Container>
      <DashboardCard
        percentual={percentPromotores - percentDetratores}
        quantidadeRespostas={quantidadeResposta}
        titulo="NPS+"
        cor="#000072"
      />

      <DashboardCard
        percentual={percentPromotores}
        quantidadeRespostas={quantidadePromotores}
        titulo="Promotores"
        cor={'#6db04c'}
      />
      <DashboardCard
        percentual={percentNeutros}
        quantidadeRespostas={quantidadeNeutros}
        titulo="Neutros"
        cor={'#e3d449'}
      />
      <DashboardCard
        percentual={percentDetratores}
        quantidadeRespostas={quantidadeDetratores}
        titulo="Detratores"
        cor="#c52128"
      />


      <DashboardCard
        percentual={percentEngajameto}
        titulo="Engajamento"
        cor="#828282"
        quantidadeRespostas={totalRead}
      />

    </Container>
  );
}
