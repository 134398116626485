import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  autoComplete?: string;
}

const Input: React.FC<InputProps> = ({ name, ...rest }, ref) => {
  const inputElementRef = useRef<HTMLInputElement>(null);

  const { registerField, defaultValue = '', fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputElementRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <input defaultValue={defaultValue} ref={inputElementRef} {...rest} />
    </Container>
  );
};

export default Input;
