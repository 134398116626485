import React from 'react';

import { AuthProvider } from './auth';
import { LoaderProvider } from './loading';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => (
  <LoaderProvider>
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  </LoaderProvider>
);

export default AppProvider;
