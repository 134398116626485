import styled from 'styled-components';

export const Card = styled.div`
  padding: 20px;
  width: 100%;

  border: 0;
  border-radius: 10px 10px;

  background-color: var(--grey);
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    width: 100%;
    height: 300px;
    border: 1;
    border-radius: 10px;
    border-color: var(--blue);

    resize: none;

    margin-bottom: 10px;

    padding: 10px;
    color: var(--blue);
    font-size: 1rem;

    ::placeholder {
      font-size: 1rem;
      color: var(--blue);
    }

    background-color: var(--background);
  }
  > textarea {
    height: 150px;
  }

  h1 {
    color: var(--text);
  }

  h2,
  h3 {
    color: var(--blue);
    margin-bottom: 8px;
  }
`;
export const Sugestoes = styled.div`
  align-content: center;
`;

export const DivInputSelect = styled.div`
  width: 80%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  align-items: center;
  justify-content: space-between;

  margin: 5px 0;
`;

export const Header = styled.div`
  width: 60%;
  margin: 20px;
  align-items: center;

  h2 {
    color: var(--text);
  }
`;
