import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: 'white' | 'blue';
}

export function Button({
  children,
  backgroundColor = 'blue',
  ...rest
}: ButtonProps) {
  return (
    <Container type="button" color={backgroundColor} {...rest}>
      {children}
    </Container>
  );
}
