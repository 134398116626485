import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;

  border-radius: 10px;
  background-color: var(--grey);
`;

export const Header = styled.div`
  width: 60%;
  margin: 20px;
  align-items: center;

  h2 {
    color: var(--text);
  }
`;
export const Table = styled.div`
  padding: 20px;
  width: 100%;
`;
