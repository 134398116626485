import React, { useCallback, useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { Button } from '../../components/Button';
import { DashboardCardRespostas } from '../../components/DashboardCardRespostas';
import { DashboardCards } from '../../components/DashboardCards';
import { DashboardPerguntaExtraCard } from '../../components/DashboardPerguntaExtraCard';
import { FiltroPesquisaModal } from '../../components/FiltroPesquisaModal';
import GraficoEvolucaoNps from '../../components/GraficoEvolucaoNps';
import { InputSelect } from '../../components/InputSelect';
import { PageContainer } from '../../components/PageContainer';
import { IDashboardDataDto } from '../../dtos/IDashboardDataDto';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';

import { useLoader } from '../../hooks/loading';
import api from '../../services/api';

import axios from 'axios';
import { GraficoNlp } from '../../components/GraficoNlp';
import { NlpData } from '../../dtos/NlpData';
import * as S from './styles';

export function Dashboard() {
  const { showLoader, hideLoader } = useLoader();

  const [dashboardData, setDashboardData] = useState<IDashboardDataDto>(
    {} as IDashboardDataDto,
  );

  const [pesquisasOptions, setPesquisasOptions] = useState<IOptionSelectDto[]>(
    [],
  );
  const [unidadesOptions, setUnidadesOptions] = useState<IOptionSelectDto[]>(
    [],
  );
  const [filtroEvolucaoPesquisas, setFiltroEvolucaoPesquisas] = useState<
    IOptionSelectDto[]
  >([]);
  const [filtroEvolucaoUnidades, setFiltroEvolucaoUnidades] = useState<
    IOptionSelectDto[]
  >([]);

  const [unidadeSelecionada, setUnidadeSelecionada] = useState<
    IOptionSelectDto
  >();
  const [pesquisaSelecionada, setPesquisaSelecionada] = useState<
    IOptionSelectDto
  >();

  const [evolucaoNpsUnidades, setEvolucaoNpsUnidades] = useState<
    IOptionSelectDto[]
  >([]);
  const [evolucaoNpsPesquisas, setEvolucaoNpsPesquisas] = useState<
    IOptionSelectDto[]
  >([]);

  const [isOpenFiltroPesquisaModal, setIsOpenFiltroPesquisaModal] = useState(
    false,
  );

  const [filtro1, setFiltro1] = useState<IOptionSelectDto[]>([]);
  const [filtro2, setFiltro2] = useState<IOptionSelectDto[]>([]);
  const [filtro3, setFiltro3] = useState<IOptionSelectDto[]>([]);
  const [filtro4, setFiltro4] = useState<IOptionSelectDto[]>([]);
  const [filtro5, setFiltro5] = useState<IOptionSelectDto[]>([]);

  const [nlpData, setNlpData] = useState<NlpData | null>();

  useEffect(() => {
    async function loadDashboard() {
      try {
        showLoader();
        const response = await api.get('/dashboard/initialData');

        const { unidades, pesquisas } = response.data;

        setUnidadesOptions(unidades);
        setPesquisasOptions(pesquisas);

        await api.get('/dashboard').then((response) => {
          setDashboardData(response.data);
        });
      } finally {
        hideLoader();
      }
    }
    loadDashboard();
  }, [showLoader, hideLoader]);

  useEffect(() => {
    setPesquisaSelecionada(pesquisasOptions[0]);
  }, [pesquisasOptions]);

  useEffect(() => {
    setUnidadeSelecionada(unidadesOptions[0]);
  }, [unidadesOptions]);

  const handleSelectPesquisa = useCallback(
    async (pesquisa: any) => {
      showLoader();

      setFiltro1([]);
      setFiltro2([]);
      setFiltro3([]);
      setFiltro4([]);
      setFiltro5([]);

      if (pesquisa.value !== '') {
        const response = await api.get(
          `/dashboard?pesquisaId=${pesquisa?.value}`,
        );

        setDashboardData({
          ...response.data,
          perguntaExtra: pesquisa.perguntaExtra,
        });

        const responseFiltros = await api.get(
          `/admin/filtros?pesquisaId=${pesquisa.value}`,
        );

        setFiltro1(responseFiltros.data.filtros1);
        setFiltro2(responseFiltros.data.filtros2);
        setFiltro3(responseFiltros.data.filtros3);
        setFiltro4(responseFiltros.data.filtros4);
        setFiltro5(responseFiltros.data.filtros5);

        await axios
          .get(
            `https://tuaopiniao-nlp-service-007bf59f4d0c.herokuapp.com/get-survey-scores?surveyId=${pesquisa?.value}`,
          )
          .then((response) => setNlpData(response.data))
          .catch(() => setNlpData(null));

        hideLoader();
      } else {
        if (unidadeSelecionada && unidadeSelecionada.value !== '') {
          const response = await api.get(
            `/dashboard?unidadeId=${unidadeSelecionada?.value}`,
          );

          setDashboardData({
            ...response.data,
            percentuaisPerguntaExtra: [],
            perguntaExtra: '',
          });
          setPesquisasOptions(response.data.pesquisasOptions);

          hideLoader();
        } else {
          const response = await api.get('/dashboard');
          setDashboardData({
            ...response.data,
            percentuaisPerguntaExtra: [],
            perguntaExtra: '',
          });

          hideLoader();
        }

        setNlpData(null);
      }

      setPesquisaSelecionada(pesquisa);
    },
    [hideLoader, showLoader, unidadeSelecionada],
  );

  async function handleSelectUnidade(unidade: any) {
    showLoader();
    setUnidadeSelecionada(unidade);

    setNlpData(null);

    if (unidade.value === '') {
      const response = await api.get('/dashboard');
      setDashboardData({
        ...response.data,
        percentuaisPerguntaExtra: [],
        perguntaExtra: '',
      });
      setPesquisasOptions(response.data.pesquisasOptions);

      hideLoader();
      return;
    }

    const response = await api.get(`/dashboard?unidadeId=${unidade?.value}`);

    setDashboardData({
      ...response.data,
      percentuaisPerguntaExtra: [],
      perguntaExtra: '',
    });

    setPesquisasOptions(response.data.pesquisasOptions);

    hideLoader();
  }

  async function handleBuscarDadosEvolucaoNpsUnidades() {
    showLoader();
    const response = await api.get('/dashboard/evolucaoNps/unidades');
    const { evolucaoNpsUnidades } = response.data;

    setFiltroEvolucaoUnidades(evolucaoNpsUnidades);

    hideLoader();
  }

  async function handleBuscarDadosEvolucaoNpsPesquisas() {
    showLoader();

    const response = await api.get('/dashboard/evolucaoNps/pesquisas');
    const { evolucaoNpsPesquisas } = response.data;

    setFiltroEvolucaoPesquisas(evolucaoNpsPesquisas);

    hideLoader();
  }

  function handleCloseFiltroPesquisaModal() {
    setIsOpenFiltroPesquisaModal(!isOpenFiltroPesquisaModal);
  }

  async function handleBuscarDados(filtros: Array<string | undefined>) {
    if (pesquisaSelecionada) {
      const response = await api.get('/dashboard/filtros?', {
        params: {
          pesquisaId: pesquisaSelecionada.value,
          filtro1: filtros[0],
          filtro2: filtros[1],
          filtro3: filtros[2],
          filtro4: filtros[3],
          filtro5: filtros[4],
        },
      });

      setDashboardData({
        ...response.data,
        perguntaExtra: pesquisaSelecionada.perguntaExtra,
      });
    }
  }

  return (
    <PageContainer title="Dashboard">
      <S.Filtro>
        <InputSelect
          options={unidadesOptions}
          placeholder="Selecione uma unidade"
          onChange={(value) => handleSelectUnidade(value)}
          value={unidadeSelecionada}
        />

        <InputSelect
          options={pesquisasOptions}
          placeholder="Selecione uma pesquisa"
          onChange={(value) => handleSelectPesquisa(value)}
          value={pesquisaSelecionada}
        />

        {(filtro1.length > 0 ||
          filtro4.length > 0 ||
          filtro2.length > 0 ||
          filtro3.length > 0 ||
          filtro5.length > 0) && (
            <Button onClick={() => setIsOpenFiltroPesquisaModal(true)}>
              Filtros da pesquisa
            </Button>
          )}
      </S.Filtro>

      <DashboardCards
        percentDetratores={dashboardData.percentDetratores}
        percentNeutros={dashboardData.percentNeutros}
        percentPromotores={dashboardData.percentPromotores}
        quantidadeDetratores={dashboardData.quantidadeDetratores}
        quantidadePromotores={dashboardData.quantidadePromotores}
        quantidadeNeutros={dashboardData.quantidadeNeutros}
        quantidadeResposta={dashboardData.quantidadeResposta}
        percentEngajameto={
          (dashboardData.quantidadeResposta / dashboardData.totalRead) * 100
        }
        totalRead={dashboardData.totalRead}
      />

      <S.Body>
        <DashboardCardRespostas
          segundaPergunta={pesquisaSelecionada?.segundaPergunta}
          respostasRuins={dashboardData.respostasRuins}
          respostasNeutras={dashboardData.respostasNeutras}
          respostasBoas={dashboardData.respostasBoas}
        />

        <DashboardPerguntaExtraCard
          perguntaExtra={dashboardData.perguntaExtra}
          percentuaisPerguntaExtra={dashboardData.percentuaisPerguntaExtra}
        />
      </S.Body>

      {nlpData &&
        nlpData.feelingsScores &&
        nlpData.feelingsScores.length !== 0 ? (
        <S.Body>
          <div>
            <S.Title>Análise geral de sentimentos</S.Title>
            <GraficoNlp feelingsScores={nlpData.feelingsScores} />
          </div>
        </S.Body>
      ) : null}

      {pesquisaSelecionada && (pesquisaSelecionada.wordCloudPromotoresUrl || pesquisaSelecionada.wordCloudNeutrosUrl || pesquisaSelecionada.wordCloudDetratoresUrl) && (
        <div>
          <S.Title>Direcionadores da NPS</S.Title>

          {
            pesquisaSelecionada.wordCloudPromotoresUrl && (<img
              src={pesquisaSelecionada.wordCloudPromotoresUrl}
              alt="Promotores"
              style={{
                 width: "33%",
              }}
            />)
          }

          {
            pesquisaSelecionada.wordCloudNeutrosUrl && (
              <img
                src={pesquisaSelecionada.wordCloudNeutrosUrl}
                alt="Neutros"
                style={{
                   width: "33%",
                }}
              />
            )
          }


          {
            pesquisaSelecionada.wordCloudDetratoresUrl && (
              <img
                src={pesquisaSelecionada.wordCloudDetratoresUrl}
                alt="Detratores"
                style={{
                   width: "33%",
                }}
              />
            )
          }



        </div>
      )}

      <S.EvolucaoNps>
        <S.Row>
          <S.Title>Evolução das pesquisas</S.Title>
          <S.DivButton>
            <Button onClick={() => handleBuscarDadosEvolucaoNpsPesquisas()}>
              Buscar dados
            </Button>
          </S.DivButton>
        </S.Row>

        <S.CardNps>
          <S.GraficoNps>
            <MultiSelect
              options={filtroEvolucaoPesquisas.filter(
                (item) => item.value !== '',
              )}
              value={evolucaoNpsPesquisas}
              onChange={setEvolucaoNpsPesquisas}
              labelledBy={'aria-labelledby'}
              overrideStrings={{
                selectSomeItems: 'Filtrar...',
                allItemsAreSelected: 'Todas as pesquisas selecionadas.',
                selectAll: 'Selecionar todas...',
                search: 'Procurar',
                clearSearch: 'Limpar',
              }}
            />

            <GraficoEvolucaoNps
              categories={evolucaoNpsPesquisas.map((item) => item.label)}
              dados={evolucaoNpsPesquisas.map((item) =>
                parseInt(item.nps ? item.nps : '0'),
              )}
            />
          </S.GraficoNps>
        </S.CardNps>
        <S.Row>
          <S.Title>Evolução das unidades</S.Title>

          <S.DivButton>
            <Button onClick={() => handleBuscarDadosEvolucaoNpsUnidades()}>
              Buscar dados
            </Button>
          </S.DivButton>
        </S.Row>
        <S.CardNps>
          <S.GraficoNps>
            <MultiSelect
              options={filtroEvolucaoUnidades.filter((item) => item.nps !== '')}
              value={evolucaoNpsUnidades}
              onChange={setEvolucaoNpsUnidades}
              labelledBy={'aria-labelledby'}
              overrideStrings={{
                selectSomeItems: 'Filtrar...',
                allItemsAreSelected: 'Todas as unidades selecionadas.',
                selectAll: 'Selecionar todas...',
                search: 'Procurar',
                clearSearch: 'Limpar',
              }}
            />
            <GraficoEvolucaoNps
              categories={evolucaoNpsUnidades.map((item) => item.label)}
              dados={evolucaoNpsUnidades.map((item) =>
                parseInt(item.nps ? item.nps : '0'),
              )}
            />
          </S.GraficoNps>
        </S.CardNps>
      </S.EvolucaoNps>

      <FiltroPesquisaModal
        isOpen={isOpenFiltroPesquisaModal}
        handleBuscarDados={handleBuscarDados}
        filtro1={filtro1}
        filtro2={filtro2}
        filtro3={filtro3}
        filtro4={filtro4}
        filtro5={filtro5}
        onRequestClose={handleCloseFiltroPesquisaModal}
      />
    </PageContainer>
  );
}
