import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import getValidationError from '../../utils/getValidationsError';
import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import Input from '../Input';

import api from '../../services/api';
import { Container } from './styles';
import { ModalTitle } from '../ModalTitle';
import { Button } from '../Button';
import TextArea from '../TextArea';

interface Data {
  nome: string;
}

interface CadastroTemplateModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  userSelected: string | undefined;
}

export function CadastroTemplateModal({
  isOpen,
  onRequestClose,
  userSelected,
}: CadastroTemplateModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: Data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome do Template é obrigatório!'),
          descricao: Yup.string().required(
            'A descricao do Template é obrigatória!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!userSelected) {
          addToast({
            type: 'error',
            title: 'Erro ao salvar Template!',
            description: 'Selecione o usuario!',
          });
        }
        await api
          .post('/admin/templates', {
            ...data,
            userId: userSelected,
          })

          .catch((err) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar o template!',
              description: err.data.message,
            });
          });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Template salvo com sucesso',
        });

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar template!',
              description: erro,
            });
          });

          return;
        }
      }
    },
    [addToast, userSelected],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Cadastrar novo template" />

        <Input name="nome" placeholder="Nome do Template" />
        <TextArea name="descricao" placeholder="Template" rows={10} />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
