import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;
export const Title = styled.h2`
  color: var(--text);
`;

export const Grafico = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  margin-top: 20px;
  border-radius: 10px;

  padding: 20px;

  align-items: center;
  justify-content: center;

  background-color: var(--grey);
  border: 1px solid var(--grey);

  strong {
    color: var(--text);
  }
`;
