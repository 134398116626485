import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root{
      --background: #ffff;
      --red: #c62e24;
      --yellow: #f5cf1b;
      --green: #8ad052;
      --blue: #000072;
      --grey: #d6d6d6;
      --text: #828282;
      --white: #ffff;
    }

  * {
    margin:0;
    padding: 0;
    outline:0;
    box-sizing: border-box;

  }

button {
  cursor: pointer
}

html {
  @media (max-width:1080px){
    font-size: 93.75%; // 15px
  }
  @media (max-width:720px){
    font-size: 87.5%; // 14px
  }
}


body{
  -webkit-font-smoothing: antialiased;
  background: var(--background);
}

body, input, textarea, button, p, a {
  font-family: "Mont Serrat", sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, strong {
  font-family: "Mont Serrat", sans-serif;
  font-weight: 600;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 120px;
}

.bm-burger-bars {
  background: var(--grey);
}

.bm-burger-bars-hover {
  background: var(--red);
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background:var(--grey);
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: var(--blue);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill:var(--grey);
}

.bm-item-list {
  color: var(--grey);
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}



.react-modal-overlay{

  background: rgba(0,0,0,0.5);

  position: fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

}

.react-modal-content {
  width: 100%;
  max-width: 720px;
  background: var(--blue);

  padding: 3rem;

  position: relative;

  border-radius: 0.25rem;

}


.react-modal-close{
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  border: 0;

  background: transparent;
  transition: filter 0.2s;

  &:hover{
    filter: brightness(0.8);
  }
}

.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -200px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

`;
