import styled from 'styled-components';

export const Filtro = styled.div`
  display: flex;

  flex-direction: column;

  margin: 10px;

  width: 50%;
`;

export const Cabecalho = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }

  grid-gap: 24px;
`;

export const CardNps = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  margin-top: 20px;
  margin-bottom: 50px;

  list-style: none;

  width: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.h2`
  color: var(--text);
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  width: 100%;

  align-items: center;
  justify-content: space-between;
`;

export const DivButton = styled.div`
  width: 20%;
`;

export const EvolucaoNps = styled.div`
  display: flex;
  flex: 1;

  width: 100%;

  flex-direction: column;

  margin-top: 20px;
  margin-bottom: 50px;
`;

export const GraficoNps = styled.div`
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;

  padding: 20px;

  background: var(--grey);
`;
