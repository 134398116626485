import { FormHandles } from '@unform/core';
import React, { useRef } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import getValidationError from '../../utils/getValidationsError';
import InputMask from '../InputMask';
import TextArea from '../TextArea';

import { ICasoDto } from '../../dtos/ICasoDto';
import api from '../../services/api';

import { Button } from '../Button';
import { ModalTitle } from '../ModalTitle';
import { Container } from './styles';

interface Data {
  nome: string;
}

interface CadastroCasoModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  caso: ICasoDto;
}

export function EdicaoCasoModal({
  isOpen,
  onRequestClose,
  caso,
}: CadastroCasoModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  async function handleSubmit(data: Data) {
    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        nota: Yup.string().required('Informe a nota.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationError(err);
        formRef.current?.setErrors(error);

        err.errors.forEach((erro) => {
          addToast({
            type: 'error',
            title: 'Atenção!',
            description: erro,
          });
        });
      }
    }

    await api.put('/admin/casos', { id: caso.id, ...data }).catch((err) => {
      addToast({
        type: 'error',
        title: 'Erro ao salvar os dados!',
        description: err.response.data.message,
      });
    });

    addToast({
      type: 'success',
      title: 'Sucesso!',
      description: 'Caso atualizado com sucesso',
    });

    onRequestClose();

    formRef.current?.reset();
  }

  async function handleRemove() {
    await api.put(`/admin/casos/remove?casoId=${caso.id}`).catch((err) => {
      addToast({
        type: 'error',
        title: 'Erro ao salvar os dados!',
        description: err.response.data.message,
      });
    });

    onRequestClose();

    formRef.current?.reset();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit} initialData={caso}>
        <ModalTitle title="Editar caso" />

        <InputMask name="nota" placeholder="Nota" mask="99" />
        <TextArea name="avaliacao" placeholder="Avaliação" rows={5} />

        <TextArea name="respostaExtra" placeholder="Pergunta Extra" rows={5} />

        <div>
          <Button type="submit" backgroundColor="white">
            Salvar
          </Button>
          <Button backgroundColor="blue" onClick={handleRemove}>
            Limpar caso
          </Button>
        </div>
      </Container>
    </Modal>
  );
}
