import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import Modal from 'react-modal';

import getValidationError from '../../utils/getValidationsError';
import closeImage from '../../assets/close.svg';
import { useToast } from '../../hooks/toast';
import * as Yup from 'yup';
import Input from '../Input';

import api from '../../services/api';
import { Container } from './styles';
import InputMask from '../../components/InputMask';
import { InputSelectForm } from '../InputSelectForm';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { ModalTitle } from '../ModalTitle';

interface InputSelectOption {
  label: string;
  value: string;
}

interface CadastroUsuarioModal {
  isOpen: boolean;
  onRequestClose(): void;
  planos: InputSelectOption[] | undefined;
}

export function CadastroUsuarioModal({
  isOpen,
  onRequestClose,
  planos,
}: CadastroUsuarioModal) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [admin, setAdmin] = useState(false);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório!'),
          email: Yup.string()
            .required('E-mail obrigatório!')
            .email('Informe um e-mail em um formato válido!'),
          password: Yup.string().required('Senha obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post('/admin/users', { ...data, admin })
          .then(() => {
            addToast({
              type: 'success',
              title: 'Sucesso!',
              description: 'Usuário criado com sucesso',
            });

            formRef.current?.reset();
          })
          .catch((err) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar usuário!',
              description: err.response.data.message,
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar usuário!',
              description: erro,
            });
          });
        }
      }
    },
    [addToast, admin],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Novo usuário" />

        <Input name="nome" placeholder="Nome da empresa" />
        <Input name="email" placeholder="E-mail" />
        <InputMask
          name="whatsapp"
          placeholder="WhatsApp"
          mask="5599999999999"
        />

        <Input
          name="password"
          type="password"
          placeholder="Senha"
          autoComplete="current-password"
        />

        <Checkbox label="Admin" checked={!!admin} onClick={setAdmin} />

        <InputSelectForm
          name="planoId"
          placeholder="Selecione um plano"
          options={planos}
        />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
