import React from 'react';
import { PageContainer } from '../../../components/PageContainer';
// import api from '../../../services/api';

export function Bullboard() {
  return (
    <PageContainer>
      <iframe
        style={{
          width: '100%',
          height: '100vh',
        }}
        title="Bull board"
        src="https://api.tuaopiniao.com.br/admin/queues"
      />
    </PageContainer>
  );
}
