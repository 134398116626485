import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 838px) {
    margin-top: 50px;
  }
`;

export const Imagem = styled.div`
  margin-bottom: 40px;
  img {
    width: 100%;
  }
`;

export const NavLinkStyled = styled(NavLink)`
  position: relative;
  display: block;
  text-decoration: none;

  font-size: 1rem;
  font-family: 'Mont Serrat', sans-serif;
  font-weight: 400;
`;

export const Button = styled.li`
  height: 40px;
  border-radius: 10px;
  padding: 10px 15px;

  color: var(--blue);
  background: var(--white);

  width: 100%;

  margin: 10px;
  position: relative;
  display: block;
  flex-direction: row;

  svg {
    float: left;
    margin-right: 10px;
    color: var(--blue);
  }
`;

export const List = styled.ul`
  margin-top: 20px;
  list-style: none;
`;
