import React from 'react';
import Chart from 'react-apexcharts';

interface IGraficoPerguntaExtraProps {
  values: number[];
}

const GraficoPerguntaExtra: React.FC<IGraficoPerguntaExtraProps> = ({
  values,
}: IGraficoPerguntaExtraProps) => {
  return (
    <Chart
      options={{
        chart: {
          height: 500,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: string) {
            return val + '%';
          },
          offsetY: -20,
          style: {
            fontSize: '14px',
            colors: ['#304758'],
          },
        },

        xaxis: {
          categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      }}
      series={[{ data: values }]}
      type="bar"
      // height={350}
      width={750}
    />
  );
};

export default GraficoPerguntaExtra;
