import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeImage from '../../assets/close.svg';
import { IPesquisaDto } from '../../dtos/IPesquisaDto';
import { useToast } from '../../hooks/toast';
import getValidationError from '../../utils/getValidationsError';
import { Checkbox } from '../Checkbox';
import Input from '../Input';
import { InputSelectForm } from '../InputSelectForm';

import api from '../../services/api';
import { Button } from '../Button';
import { ModalTitle } from '../ModalTitle';
import TextArea from '../TextArea';
import { Container, Row } from './styles';

interface InputSelectOption {
  label: string;
  value: string;
}

interface EditarPesquisaModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  pesquisa?: IPesquisaDto;
  userUnidades: InputSelectOption[] | undefined;
  userTemplates: InputSelectOption[] | undefined;
  userIdSelected: string;
}

export function EditarPesquisaModal({
  isOpen,
  onRequestClose,
  userTemplates,
  userUnidades,
  userIdSelected,
  pesquisa,
}: EditarPesquisaModalProps) {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [template, setTemplate] = useState({} as InputSelectOption);
  const [unidade, setUnidade] = useState({} as InputSelectOption);

  const [analisarAvaliacoes, setAnalisarAvaliacoes] = useState(
    pesquisa?.analisarAvaliacoes,
  );

  useEffect(() => {
    if (userTemplates) {
      userTemplates.forEach((element) => {
        if (element.value === pesquisa?.template_id) {
          setTemplate(element);
        }
      });
    }

    if (userUnidades) {
      userUnidades.forEach((element) => {
        if (element.value === pesquisa?.unidade_id) {
          setUnidade(element);
        }
      });
    }
  }, [userTemplates, userUnidades, pesquisa]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome da pesquisa é obrigatório!'),
          apresentacaoEmpresa: Yup.string().required(
            'A apresentação da empresa é obrigatório!',
          ),
          dtEncerramento: Yup.date().min(
            new Date(),
            'A data de encerramento não pode ser menor que o dia atual!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put('/admin/pesquisas', {
            id: pesquisa?.id,
            ...data,
            user_id: userIdSelected,
            analisarAvaliacoes,
          })
          .then((response) => {
            addToast({
              type: 'success',
              title: 'Sucesso!',
              description: 'Pesquisa salva com sucesso',
            });

            formRef.current?.reset();
            onRequestClose();
          })
          .catch((error) => {
            addToast({
              description: error.response.data.message,
              title: 'Erro!',
              type: 'error',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Atenção!',
              description: erro,
            });
          });
        }
      }
    },
    [userIdSelected, analisarAvaliacoes, addToast, onRequestClose, pesquisa],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImage} alt="Fechar modal" />
      </button>

      <Container ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title="Editar pesquisa" />

        <Input name="nome" placeholder="Nome" defaultValue={pesquisa?.nome} />
        <Input
          name="apresentacaoEmpresa"
          placeholder="Apresentação"
          defaultValue={pesquisa?.apresentacaoEmpresa}
        />
        <TextArea
          name="segundaPergunta"
          placeholder="Segunda pergunta"
          defaultValue={pesquisa?.segundaPergunta}
        />
        <TextArea
          name="perguntaExtra"
          placeholder="Pergunta extra"
          defaultValue={pesquisa?.perguntaExtra}
        />

        <Row>
          <Input
            name="numeroTwilio"
            placeholder="Número do twilio"
            defaultValue={pesquisa?.numeroTwilio}
          />
          <Input
            name="periodoCarencia"
            placeholder="Tempo de carência"
            type="number"
            defaultValue={pesquisa?.periodoCarencia}
          />
        </Row>

        <InputSelectForm
          name="template_id"
          placeholder="Selecione um template"
          options={userTemplates}
          defaultValue={template}
        />
        <InputSelectForm
          name="unidade_id"
          placeholder="Selecione uma unidade"
          options={userUnidades}
          defaultValue={unidade}
        />

        <Row>
          <Checkbox
            checked={analisarAvaliacoes || false}
            onClick={() => setAnalisarAvaliacoes(!analisarAvaliacoes)}
            label="Analisar Avaliações"
          />

          <Input
            name="dtEncerramento"
            placeholder="Data de encerramento"
            type="date"
          />
        </Row>

        <Input
          name="npsQuestion"
          placeholder="Pergunta NPS site"
          defaultValue={pesquisa?.npsQuestion}
        />
        <Input
          name="secondQuestion"
          placeholder="Segunda pergunta site"
          defaultValue={pesquisa?.secondQuestion}
        />
        <Input
          name="thirdQuestion"
          placeholder="Terceira pergunta site"
          defaultValue={pesquisa?.thirdQuestion}
        />

        <Button type="submit" backgroundColor="white">
          Salvar
        </Button>
      </Container>
    </Modal>
  );
}
