import React from 'react';
import {
  Page,
  Text,
  Document,
  StyleSheet,
  Image,
  View,
} from '@react-pdf/renderer';
import logo from '../../assets/logoAzul.png';
import npsLogo from '../../assets/nps+.png';
import { NPS } from '../PDFViewerModal';
import { ICasoDto } from '../../dtos/ICasoDto';
import { decryptData } from '../../utils/decryptData';

interface PDFViewerProps {
  casos: ICasoDto[];
  pesquisa?: {
    nome: string;
    perguntaExtra?: string;
  };
  incluirNome: boolean;
  incluirContato: boolean;
  incluirAvaliacao: boolean;
  incluirRespostaExtra: boolean;
  nps: NPS;
  closeLoader(): void;
}

export function NpsPDFDocument({
  casos,
  pesquisa,
  incluirNome,
  incluirContato,
  incluirAvaliacao,
  incluirRespostaExtra,
  closeLoader,
  nps,
}: PDFViewerProps) {
  return (
    <Document onRender={closeLoader}>
      <Page style={styles.body}>
        <View style={styles.header}>
          <Image style={styles.image} source={logo} />
          <Image style={styles.image} source={npsLogo} />
        </View>

        <View style={styles.content}>
          <View>
            <View style={styles.row}>
              <Text style={styles.label}>Data de exportação: </Text>
              <Text style={styles.value}>
                {new Date().toLocaleDateString()}
              </Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.label}>Pesquisa: </Text>
              <Text style={styles.value}>{pesquisa?.nome}</Text>
            </View>

            {incluirRespostaExtra && (
              <>
                <Text style={styles.label} break>
                  Pergunta Extra:{' '}
                </Text>
                <Text style={styles.value} break>
                  {pesquisa?.perguntaExtra}
                </Text>
              </>
            )}
          </View>

          <View style={styles.npsCards}>
            <View style={styles.cardContainer}>
              <View style={styles.cardHeader}>
                <View style={styles.cardValores}>
                  <Text style={styles.textTituloNps}>NPS</Text>

                  {nps.percentPromotores - nps.percentDetratores ? (
                    <Text style={styles.percentualNps}>
                      {(
                        nps.percentPromotores - nps.percentDetratores
                      ).toPrecision(3)}
                      %
                    </Text>
                  ) : (
                    <Text style={styles.percentualNps}>0%</Text>
                  )}
                </View>
                <View style={styles.cardFooter}>
                  <Text style={styles.textRespostasNps}>
                    Total de respostas:{' '}
                    {nps.quantidadeDetratores +
                      nps.quantidadeNeutros +
                      nps.quantidadePromotores}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.cardContainer}>
              <View style={styles.cardHeader}>
                <View style={styles.cardValores}>
                  <Text style={styles.textTituloPromotores}>Promotores</Text>

                  {nps.percentPromotores ? (
                    <Text style={styles.percentualPromotores}>
                      {nps.percentPromotores.toPrecision(3)}%
                    </Text>
                  ) : (
                    <Text style={styles.percentualPromotores}>0%</Text>
                  )}
                </View>

                <View style={styles.cardFooter}>
                  <Text style={styles.textRespostaPromotores}>
                    Total de respostas: {nps.quantidadePromotores}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.cardContainer}>
              <View style={styles.cardHeader}>
                <View style={styles.cardValores}>
                  <Text style={styles.textTituloNeutros}>Neutros</Text>
                  {nps.percentNeutros ? (
                    <Text style={styles.percentualNeutros}>
                      {nps.percentNeutros.toPrecision(3)}%
                    </Text>
                  ) : (
                    <Text style={styles.percentualNeutros}>0%</Text>
                  )}
                </View>
                <View style={styles.cardFooter}>
                  <Text style={styles.textRespostaNeutros}>
                    Total de respostas: {nps.quantidadeNeutros}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.cardContainer}>
              <View style={styles.cardHeader}>
                <View style={styles.cardValores}>
                  <Text style={styles.textTituloDetratores}>Detratores</Text>

                  {nps.percentDetratores ? (
                    <Text style={styles.percentualDetratores}>
                      {nps.percentDetratores.toPrecision(3)}%
                    </Text>
                  ) : (
                    <Text style={styles.percentualDetratores}>0%</Text>
                  )}
                </View>

                <View style={styles.cardFooter}>
                  <Text style={styles.textRespostasDetratores}>
                    Total de respostas: {nps.quantidadeDetratores}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.casos}>
            {casos
              .sort((a, b) => b.nota - a.nota)
              .map((caso) => {
                if (caso.avaliacao) {
                  return (
                    <View key={caso.id} style={styles.caso} wrap={false}>
                      <View style={styles.rowBetween}>
                        <View style={styles.row}>
                          <Text style={styles.label}>Nota: </Text>
                          <Text style={styles.value}>{caso.nota}</Text>
                        </View>

                        {incluirNome ? (
                          <View style={styles.row}>
                            <Text style={styles.label}>Nome: </Text>
                            <Text style={styles.value}>{caso.nome}</Text>
                          </View>
                        ) : null}

                        {incluirContato ? (
                          <View style={styles.row}>
                            <Text style={styles.label}>Contato: </Text>
                            <Text style={styles.value}>
                              {decryptData(caso.telefone)}
                            </Text>
                          </View>
                        ) : null}
                      </View>

                      {incluirAvaliacao && (
                        <>
                          <Text style={styles.label}>Avaliação: </Text>

                          <View style={styles.viewAvaliacao}>
                            <Text style={styles.avaliacao}>
                              {caso.avaliacao}
                            </Text>
                          </View>
                        </>
                      )}

                      {caso.respostaExtra && incluirRespostaExtra ? (
                        <>
                          <Text style={styles.label}>Resposta extra: </Text>

                          <View style={styles.viewAvaliacao}>
                            <Text style={styles.avaliacao}>
                              {caso.respostaExtra}
                            </Text>
                          </View>
                        </>
                      ) : null}
                    </View>
                  );
                }
                return null;
              })}
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: { width: 125 },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  content: {
    display: 'flex',
    flex: 1,
  },
  casos: {
    display: 'flex',
    flex: 1,
    marginTop: 20,
  },
  caso: {
    borderRadius: '10',
    backgroundColor: '#deecf2',
    padding: 10,
    marginBottom: 10,
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 'bold',
    fontFamily: 'Times-Roman',
  },
  value: {
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  viewAvaliacao: {
    backgroundColor: '#FFF',
    padding: 10,
    borderRadius: 10,
    marginTop: 5,
  },
  avaliacao: {
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  npsCards: {
    display: 'flex',
    flexDirection: 'row',

    marginTop: 20,
    marginBottom: 10,

    listStyle: 'none',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 100,
  },
  cardHeader: {
    display: 'flex',
    flex: 1,
    height: '80%',
    width: '100%',
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardValores: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTituloNps: { color: '#292c52', fontSize: 15 },
  textTituloPromotores: { color: '#4cb804', fontSize: 15 },
  textTituloNeutros: { color: '#ffcc00', fontSize: 15 },
  textTituloDetratores: { color: '#c92d22', fontSize: 15 },
  percentualNps: { color: '#292c52', fontSize: 15, fontWeight: 600 },
  percentualPromotores: { color: '#4cb804', fontSize: 15, fontWeight: 600 },
  percentualNeutros: { color: '#ffcc00', fontSize: 15, fontWeight: 600 },
  percentualDetratores: { color: '#c92d22', fontSize: 15, fontWeight: 600 },

  cardFooter: {
    height: '20%',
    width: '100%',

    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  },
  textRespostasNps: { color: '#292c52', fontSize: 9 },
  textRespostaPromotores: { color: '#4cb804', fontSize: 9 },
  textRespostaNeutros: { color: '#ffcc00', fontSize: 9 },
  textRespostasDetratores: { color: '#c92d22', fontSize: 9 },
});
