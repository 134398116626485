import React, { useEffect, useState, useRef } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Button } from '../../components/Button';
import FileInput from '../../components/FileInput';
import { PageContainer } from '../../components/PageContainer';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import * as S from './styles';
import { InputSelect } from '../../components/InputSelect';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';
import { useLoader } from '../../hooks/loading';

export function EnviarContatosPesquisa() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { showLoader, hideLoader } = useLoader();
  const formRefContato = useRef<FormHandles>(null);
  const [pesquisasOptions, setPesquisasOptions] = useState<
    Array<IOptionSelectDto>
  >([]);

  const [pesquisaSelected, setPesquisaSelected] = useState(
    {} as IOptionSelectDto,
  );

  useEffect(() => {
    api.get('/pesquisas').then((response) => {
      setPesquisasOptions(
        response.data.map((pesquisa: any) => ({
          value: pesquisa.id,
          label: pesquisa.nome,
        })),
      );
    });
  }, [user]);

  async function handleSelectPesquisa(value: IOptionSelectDto) {
    if (value) {
      setPesquisaSelected(value);
    }
  }

  async function handleImportarContatos(data: any) {
    try {
      if (!data.file) {
        addToast({
          type: 'error',
          description: 'Nenhum arquivo importado!',
          title: 'Erro!',
        });

        return;
      }

      showLoader();
      const formData = new FormData();
      formData.append('file', data.file);

      await api.post(
        `/pesquisas/importar-contatos?pesquisa=${pesquisaSelected.label}&usuario=${user.nome}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      addToast({
        type: 'success',
        description: 'Contatos importados com sucesso!',
        title: 'Tudo certo!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        description: 'Erro ao importar dados, tente novamente!',
        title: 'Erro!',
      });
    } finally {
      setInterval(() => {
        hideLoader();

        window.location.reload();
      }, 1000);
    }
  }

  return (
    <PageContainer title="Enviar contatos">
      <S.Header>
        <InputSelect
          placeholder="Selecione uma pesquisa"
          onChange={(value) => handleSelectPesquisa(value as IOptionSelectDto)}
          options={pesquisasOptions}
        />
      </S.Header>
      <S.Content>
        <S.FormContainer>
          <h2> Adicionar contatos </h2>

          <div>
            <Form ref={formRefContato} onSubmit={handleImportarContatos}>
              <S.Row>
                <FileInput name="file" />
                <Button type="submit">Importar contatos</Button>
              </S.Row>

              <a
                href="https://docs.google.com/spreadsheets/d/1uz3C6IjRQkYHAbdp3XlWjwFMgMEBsh4x4vSWKR24Klo/edit?usp=sharing"
                target="blanck"
              >
                Baixe a planilha modelo
              </a>
            </Form>
          </div>
        </S.FormContainer>
      </S.Content>
    </PageContainer>
  );
}
