import React, { AllHTMLAttributes } from 'react';
import * as S from './styles';

import logo from '../../assets/logoBranca.png';

type ContainerProps = AllHTMLAttributes<HTMLDivElement>;

export function PageContainer({ title, children }: ContainerProps) {
  return (
    <>
      <S.Header>
        <img src={logo} alt="Tua Opinião" />
      </S.Header>

      <S.Container>
        {title && <S.Title>{title}</S.Title>}

        {children}
      </S.Container>
    </>
  );
}
