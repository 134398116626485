import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  width: 250px;

  height: 4rem;

  border-radius: 0.25rem;
  border: 1px solid var(--text);

  background-color: var(--background);
  /* label {
    color: var(--grey);
  } */
`;
