import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { InputSelect } from '../../components/InputSelect';
import { PageContainer } from '../../components/PageContainer';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';
import { useLoader } from '../../hooks/loading';
import api from '../../services/api';
import * as S from './styles';

type IDisparoPesquisaLogs = {
  id: string;
  pesquisaId: string;
  totalEnviados: number;
  totalEmCarencia: number;
  totalContatos: number;
  totalDelivered: number;
  totalSent: number;
  totalRead: number;
  totalErrors: number;
  totalUndelivered: number;
  created_at: string;
};

export function DadosPesquisa() {
  const { showLoader, hideLoader } = useLoader();

  const [disparoPesquisaLogs, setDisparoPesquisaLogs] = useState<
    IDisparoPesquisaLogs
  >();
  const [pesquisasOptions, setPesquisasOptions] = useState<
    Array<IOptionSelectDto>
  >([]);

  const [pesquisaSelected, setPesquisaSelected] = useState<IOptionSelectDto>(
    pesquisasOptions[0],
  );

  const [totalRespostas, setTotalRespostas] = useState(0);

  useEffect(() => {
    api.get('/pesquisas').then((response) => {
      setPesquisasOptions(
        response.data.map((pesquisa: any) => ({
          value: pesquisa.id,
          label: pesquisa.nome,
        })),
      );
    });
  }, []);

  function handleSelectPesquisa(pesquisa: any) {
    showLoader();

    api.get(`/pesquisas/logs/${pesquisa.value}`).then((response) => {
      setDisparoPesquisaLogs(response.data.logs);
      setTotalRespostas(response.data.totalDeCasos);
    });

    hideLoader();
    setPesquisaSelected(pesquisa);
  }

  function handleAtualizarDados() {
    showLoader();

    api
      .post(`/pesquisas/logs/${pesquisaSelected.value}`)
      .then(() => {
        api
          .get(`/pesquisas/logs/${pesquisaSelected.value}`)
          .then((response) => {
            setDisparoPesquisaLogs(response.data.logs);
            setTotalRespostas(response.data.totalDeCasos);
          });
      })
      .finally(hideLoader);
  }

  return (
    <PageContainer title="Estatística das pesquisas">
      <S.Header>
        <InputSelect
          onChange={(value) => handleSelectPesquisa(value)}
          placeholder="Selecione uma pesquisa"
          options={pesquisasOptions}
          value={pesquisaSelected}
        />
      </S.Header>

      {pesquisaSelected && disparoPesquisaLogs && (
        <S.Content>
          <S.DataGerado>
            Dados gerados em:{' '}
            {new Date(disparoPesquisaLogs.created_at).toLocaleString()}
          </S.DataGerado>

          <S.Row>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que estavam na sua lista.
              </span>
              <strong>Total de contatos:</strong>
              <p> {disparoPesquisaLogs.totalContatos}</p>
            </S.Card>

            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que leram a mensagem inicial enviada
                por nós.
              </span>

              <strong>Total de entregue:</strong>
              <p>{disparoPesquisaLogs.totalRead}</p>
            </S.Card>

            <S.Card className="tooltip">
              <span className="tooltiptext">
                Total de respostas da pesquisa.
              </span>

              <strong>Total de respostas:</strong>
              <p>{totalRespostas}</p>
            </S.Card>

            <S.Card className="tooltip">
              <span className="tooltiptext">Taxa de resposta da pesquisa.</span>

              <strong>Taxa de resposta:</strong>
              <p>
                {parseFloat(
                  (
                    (totalRespostas / disparoPesquisaLogs.totalRead) *
                    100
                  ).toString(),
                ).toFixed(2) + '%'}
              </p>
            </S.Card>
          </S.Row>
          <Button onClick={handleAtualizarDados}>Atualizar dados</Button>
        </S.Content>
      )}
    </PageContainer>
  );
}
