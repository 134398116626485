import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  padding: 20px;
  margin-bottom: 5px;
  width: 100%;

  border: 0;
  border-radius: 10px 10px;

  background-color: #deecf2;
`;

export const TableContainer = styled.div`
  padding: 20px;
  width: 100%;

  border: 0;
  border-radius: 10px 10px;

  background-color: #deecf2;
`;

export const Fields = styled.div`
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  align-items: center;

  margin-bottom: 16px;
  div {
    margin-top: 0;
    max-height: 56px;
  }

  button {
    margin-top: 0;
    max-height: 56px;
  }
`;

export const ContainerFields = styled.div`
  width: 50%;
  margin-top: 20px;

  background: #fff;
  border-radius: 10px;

  padding: 8px;
`;

export const Header = styled.div`
  width: 60%;

  margin-bottom: 20px;

  align-items: center;
`;
