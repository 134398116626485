import React from 'react';

import {
  Container,
  Footer,
  Header,
  Percentual,
  Respostas,
  Titulo,
  Valores,
} from './styles';

interface DashboardCardProps {
  percentual: number;
  quantidadeRespostas: number;
  titulo?: string;

  cor: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  percentual,
  quantidadeRespostas,
  titulo,
  cor,
}) => {
  return (
    <Container color={cor}>
      <Header>
        <Valores>
          <Titulo>{titulo}</Titulo>

          {percentual ? (
            <Percentual>{percentual === Infinity ? 'N/A' : percentual.toFixed(2) + '%'}</Percentual>
          ) : (
            <Percentual>0%</Percentual>
          )}
        </Valores>
      </Header>

      <Footer>
        {titulo === 'Engajamento' ? (
          <Respostas>Total de lidos: {quantidadeRespostas}</Respostas>
        ) : (
          <Respostas>Total de respostas: {quantidadeRespostas}</Respostas>
        )}
      </Footer>
    </Container>
  );
};

export default DashboardCard;
