import React, { useState } from 'react';
import { scaleRotate as Menu } from 'react-burger-menu';
import { Switch } from 'react-router-dom';

import {
  FiBarChart2,
  FiFileText,
  FiLogOut,
  FiPaperclip,
  FiPlus,
  FiSearch,
} from 'react-icons/fi';

import logo from '../../assets/logoBranca.png';

import { Casos } from '../../pages/Casos';
import { DadosPesquisa } from '../../pages/DadosPesquisa';
import { Dashboard } from '../../pages/Dashboard';
import { EnviarContatosPesquisa } from '../../pages/EnviarContatosPesquisa';

import { useAuth } from '../../hooks/auth';

import Route from '../../routes/Route';

import { SendSurveyPage } from '../../pages/SendSurveyPage';
import { Button, Container, Content, Imagem, NavLinkStyled } from './styles';

const Sidebar: React.FC = () => {
  const { signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const routes = [
    { key: 0, label: 'Dashboard', path: '/user/dashboard' },
    { key: 1, label: 'NPS', path: '/user/casos' },
    { key: 2, label: 'Dados pesquisa', path: '/user/dados-pesquisa' },
    {
      key: 3,
      label: 'Enviar contatos',
      path: '/user/enviar-contatos-pesquisa',
    }, {
      key: 4,
      label: 'Enviar pesquisa',
      path: '/user/enviar-pesquisa',
    },
  ];

  return (
    <Container id="outer-container">
      <Menu
        isOpen={isOpen}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      >
        <Imagem>
          <img src={logo} alt="Tua Opinião" />
        </Imagem>

        {routes.map(({ key, label, path }) => (
          <NavLinkStyled
            key={key}
            to={path}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Button>
              {key === 1 ? (
                <FiPaperclip size={20} />
              ) : key === 0 ? (
                <FiBarChart2 size={20} />
              ) : key === 2 ? (
                <FiPlus size={20} />
              ) : key === 3 ? (
                <FiFileText size={20} />
              ): key === 4 ? (
                <FiSearch size={20} />
              ) : null}
              <p>{label}</p>
            </Button>
          </NavLinkStyled>
        ))}

        <Button onClick={() => signOut()}>
          <FiLogOut size={20} />
          <p>Sair</p>
        </Button>
      </Menu>

      <Content id="page-wrap">
        <Switch>
          <Route path="/user/dashboard" exact component={Dashboard} isPrivate />
          <Route path="/user/casos" exact component={Casos} isPrivate />
          <Route
            path="/user/dados-pesquisa"
            exact
            component={DadosPesquisa}
            isPrivate
          />
          <Route
            path="/user/enviar-contatos-pesquisa"
            exact
            component={EnviarContatosPesquisa}
            isPrivate
          />
          <Route
            path={"/user/enviar-pesquisa"}
            exact
            component={SendSurveyPage}
            isPrivate
          />
        </Switch>
      </Content>
    </Container>
  );
};

export default Sidebar;
