import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;

  textarea {
    width: 100%;
    padding: 1.5rem;

    height: 4rem;

    border-radius: 0.25rem;
    border: 1px solid var(--text);
    background: var(--background);

    font-weight: 400;
    font-size: 1rem;

    & + input {
      margin-top: 1rem;
    }
  }
`;
