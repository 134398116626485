import React, { useRef, useState, useCallback } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import getValidationError from '../../../utils/getValidationsError';

import api from '../../../services/api';

import { Tabela } from './TabelaUnidades';

import { Button } from '../../../components/Button';
import Input from '../../../components/Input';
import { useToast } from '../../../hooks/toast';

import { Content, ContainerFields, TableContainer, Header } from './styles';
import Select from 'react-select';
import { useAuth } from '../../../hooks/auth';
import { PageContainer } from '../../../components/PageContainer';

interface Unidade {
  id: string;
  nome: string;
}

interface UserOption {
  label: string;
  value: string;
}

interface Data {
  nome: string;
}

const Unidades: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user } = useAuth();
  const { addToast } = useToast();
  const [unidades, setUnidades] = useState<Unidade[]>([]);

  const [userSelected, setUserSelected] = useState<UserOption>();

  const handleSelectUser = useCallback(async (value: any) => {
    setUserSelected(value);

    const response = await api.get(`/admin/unidades?userId=${value.value}`);

    setUnidades(response.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: Data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('O nome da unidade é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!userSelected) {
          addToast({
            type: 'error',
            title: 'Erro ao salvar unidade!',
            description: 'Selecione o usuario!',
          });
        }
        await api
          .post('/admin/unidades', {
            ...data,
            userId: userSelected?.value,
          })
          .then((response) => {
            setUnidades((state) => [...state, response.data]);
          })
          .catch((err) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar unidade!',
              description: err.response.data.message,
            });
          });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Unidade salva com sucesso',
        });

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const error = getValidationError(err);
          formRef.current?.setErrors(error);

          err.errors.forEach((erro) => {
            addToast({
              type: 'error',
              title: 'Erro ao salvar unidade!',
              description: erro,
            });
          });

          return;
        }
      }
    },
    [addToast, userSelected],
  );

  const handleDelete = useCallback(
    async (arraySelected: string[]) => {
      try {
        await api.delete(`/admin/unidades?id=${arraySelected[0]}`);

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Deletado com sucesso!',
        });

        await handleSelectUser(userSelected);
      } catch (error) {
        addToast({
          description:
            'A unidade selecionada não pode ser excluída pois está vinculada a uma pesquisa!',
          title: 'Atenção!',
        });
      }
    },
    [addToast, handleSelectUser, userSelected],
  );

  return (
    <PageContainer>
      <Content>
        <Header>
          <h2>Selecione o Usuário</h2>
          <Select
            name="user"
            options={user.users}
            onChange={(value) => handleSelectUser(value)}
          />
        </Header>
        <ContainerFields>
          <h2> Cadastrar nova unidade </h2>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="nome" placeholder="Nome da unidade" />

            <Button type="submit">Salvar</Button>
          </Form>
        </ContainerFields>
        <TableContainer>
          <Tabela rows={unidades} handleDelete={handleDelete} />
        </TableContainer>
      </Content>
    </PageContainer>
  );
};

export default Unidades;
