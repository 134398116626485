import React, { useCallback, useEffect, useState } from 'react';

import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '../../components/Button';
import Charts from '../../components/Charts';
import { PageContainer } from '../../components/PageContainer';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loading';
import { useToast } from '../../hooks/toast';

import { FiltroPesquisaModal } from '../../components/FiltroPesquisaModal';
import { InputSelect } from '../../components/InputSelect';
import { PDFViewerModal } from '../../components/PDFViewerModal';
import { ICasoDto } from '../../dtos/ICasoDto';
import { IOptionSelectDto } from '../../dtos/IOptionSelectDto';

import api from '../../services/api';
import { decryptData } from '../../utils/decryptData';
import { Tabela } from '../Admin/Casos/TabelaCasos';
import * as S from './styles';

export function Casos() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { showLoader, hideLoader } = useLoader();
  const [pesquisasOptions, setPesquisasOptions] = useState<
    IOptionSelectDto[]
  >();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);

  const [dsSugestaoInteracao, setDsSugestaoInteracao] = useState('');
  const [casoSelected, setCasoSeleceted] = useState<ICasoDto | null>(null);
  const [pesquisa, setPesquisa] = useState<IOptionSelectDto>();
  const [casos, setCasos] = useState<ICasoDto[]>([]);

  const [filtro1, setFiltro1] = useState<IOptionSelectDto[]>([]);
  const [filtro2, setFiltro2] = useState<IOptionSelectDto[]>([]);
  const [filtro3, setFiltro3] = useState<IOptionSelectDto[]>([]);
  const [filtro4, setFiltro4] = useState<IOptionSelectDto[]>([]);
  const [filtro5, setFiltro5] = useState<IOptionSelectDto[]>([]);
  const [isOpenFiltroPesquisaModal, setIsOpenFiltroPesquisaModal] = useState(
    false,
  );

  useEffect(() => {
    async function loadDashboard() {
      showLoader();

      setCasos([]);
      setCasoSeleceted(null);

      api
        .get(`/pesquisas`)
        .then((response) => {
          if (response.data) {
            setPesquisasOptions(
              response.data.map((pesquisa: any) => ({
                value: pesquisa.id,
                label: pesquisa.nome,
                perguntaExtra: pesquisa.perguntaExtra,
                segundaPergunta: pesquisa.segundaPergunta,
              })),
            );
          }
        })
        .catch((err) => {
          addToast({
            type: 'error',
            title: 'Erro!',
            description: err.data.message,
          });
        })
        .finally(hideLoader);
    }
    loadDashboard();
  }, [user, addToast, hideLoader, showLoader]);

  function handleVerInteracao(row: ICasoDto) {
    showLoader();

    api
      .get(`/interacao?id=${row.id}`)
      .then((response) => {
        if (row.nota <= 6) {
          const { arraySugestoes, sugestaoFinal } = response.data.responseMl;

          setCasoSeleceted({
            ...row,
            dsSugestao: sugestaoFinal,
            sugestoes: arraySugestoes,
          });

          setDsSugestaoInteracao(sugestaoFinal);
          return;
        }

        setCasoSeleceted({ ...row });
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: err.response.data.message,
        });
      });
    hideLoader();
  }

  async function handleSelectPesquisa(value: any) {
    const dataTable: ICasoDto[] = [];
    showLoader();

    api
      .get(`/casos?pesquisaId=${value.value}`)
      .then((response) => {
        response.data.forEach((item: any) => {
          dataTable.push({
            ...item,
            contato: item.contato.nome,
            telefone: item.contato.telefone,
            nota: parseInt(item.nota),
          });
        });
        setCasos(dataTable);
        setPesquisa(value);
        setCasoSeleceted(null);
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: err.response.data.message,
        });
      });

    const response = await api.get(`/admin/filtros?pesquisaId=${value.value}`);

    setFiltro1(response.data.filtros1);
    setFiltro2(response.data.filtros2);
    setFiltro3(response.data.filtros3);
    setFiltro4(response.data.filtros4);
    setFiltro5(response.data.filtros5);

    hideLoader();
  }

  function handleOpenPdfModalViewer() {
    if (
      filtro1.length > 0 ||
      filtro4.length > 0 ||
      filtro2.length > 0 ||
      filtro3.length > 0 ||
      filtro5.length > 0
    ) {
      if (
        (filtro1.length > 0 ||
          filtro4.length > 0 ||
          filtro2.length > 0 ||
          filtro3.length > 0 ||
          filtro5.length > 0) &&
        casos.length < 300
      ) {
        setOpenPdfViewer(true);
      } else {
        addToast({
          description: 'Filtre os dados para fazer a impressão do relatório. ',
          title: 'Aviso.',
          type: 'info',
        });
      }
    }
    setOpenPdfViewer(true);
    return;
  }

  function handleClosePDFViewerModal() {
    setOpenPdfViewer(false);
  }

  async function handleFiltrarDadosPesquisa(
    filtros: Array<string | undefined>,
  ) {
    if (pesquisa) {
      await api
        .get(
          `/casos/filtros?pesquisaId=${pesquisa.value}&filtro1=${filtros[0]}&filtro2=${filtros[1]}&filtro3=${filtros[2]}&filtro4=${filtros[3]}&filtro5=${filtros[4]}`,
        )
        .then((response) => {
          setCasos(response.data);
        })
        .catch((err) => {
          addToast({
            type: 'error',
            description: err.response.data.message,
            title: 'Atenção!',
          });
        });

      handleRequestCloseFiltroPesquisaModal();
    }
  }

  function handleRequestCloseFiltroPesquisaModal() {
    setIsOpenFiltroPesquisaModal(false);
  }

  const handleSendWhatsAppMessage = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${casoSelected?.nome}&text=
      ${dsSugestaoInteracao.split(' ').join('%20').split('\n').join('%20')}`,
      '_blank',
    );
  }, [casoSelected, dsSugestaoInteracao]);

  async function handleExportAllData() {
    try {
      if (!pesquisa) {
        addToast({
          title: 'Ops!',
          description: 'Selecione uma pesquisa antes de exportar!',
          type: 'info',
        });
        return;
      }
      showLoader();
      const response = await api.get(
        `/casos?pesquisaId=${pesquisa?.value}&all=true`,
      );

      if (!response.data) {
        addToast({
          title: 'Ops!',
          description: 'Nenhum dado encontrado!',
          type: 'info',
        });
        return;
      }

      const xlsxData: any[] = [];

      response.data.forEach((item: any) => {
        xlsxData.push({
          pesquisa: pesquisa.label,
          data: new Intl.DateTimeFormat('pt-BR').format(
            new Date(item.updated_at),
          ),
          nome: item.nome,
          telefone: decryptData(item.telefone),
          nota: item.nota,
          avaliacao: item.avaliacao,
          respostaExtra: item.respostaExtra,
          filtro1: item.filtro1,
          filtro2: item.filtro2,
          filtro3: item.filtro3,
          filtro4: item.filtro4,
          filtro5: item.filtro5,
        });
      });

      const ws = XLSX.utils.json_to_sheet(xlsxData);
      const wb = {
        Sheets: { data: ws },
        SheetNames: ['data'],
      };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });

      FileSaver.saveAs(
        data,
        pesquisa.label +
          '_' +
          new Date()
            .toLocaleDateString()
            .replace('/', '-')
            .replace('/', '-')
            .replace('/', '-') +
          '.xlsx',
      );
    } catch (err) {
      console.log(err);

      addToast({
        title: 'Erro!',
        description: 'Erro ao gerar relatorio!',
        type: 'error',
      });
    } finally {
      hideLoader();
    }
  }

  return (
    <PageContainer>
      <S.Card>
        <S.Row>
          <S.DivInputSelect>
            <InputSelect
              onChange={(value: any) => handleSelectPesquisa(value)}
              placeholder="Selecione uma pesquisa"
              options={pesquisasOptions}
              value={pesquisa}
            />
          </S.DivInputSelect>

          {(filtro1.length > 0 ||
            filtro4.length > 0 ||
            filtro2.length > 0 ||
            filtro3.length > 0 ||
            filtro5.length > 0) && (
            <Button onClick={() => setIsOpenFiltroPesquisaModal(true)}>
              Filtros da pesquisa
            </Button>
          )}
        </S.Row>
        <Tabela rows={casos} handleVerInteracao={handleVerInteracao} />

        {pesquisa && pesquisa.value ? (
          <S.Row>
            <Button onClick={handleOpenPdfModalViewer}>Exportar dados</Button>
            <Button onClick={handleExportAllData}>
              Exportar todos os dados
            </Button>
          </S.Row>
        ) : null}

        {casoSelected?.avaliacao ? (
          <S.Content>
            <h1>Resposta do cliente</h1>
            <h2>Nota {casoSelected?.nota}</h2>

            <h3>{pesquisa?.segundaPergunta}</h3>

            <textarea
              maxLength={60000}
              readOnly
              value={casoSelected?.avaliacao}
            />

            <h3>{pesquisa?.perguntaExtra}</h3>

            {
              !!casoSelected?.respostaExtra && (
                <textarea
                maxLength={60000}
                readOnly
                value={casoSelected?.respostaExtra}
              />
              )
            }


            {casoSelected?.nota && casoSelected?.nota <= 7 ? (
              <S.Sugestoes>
                {casoSelected?.sugestoes &&
                casoSelected?.sugestoes.length !== 0 ? (
                  <>
                    <h2>Sugestão de interação</h2>

                    <Charts value={casoSelected?.sugestoes} />

                    <h3>Editar mensagem</h3>
                    <textarea
                      defaultValue={casoSelected?.dsSugestao}
                      value={dsSugestaoInteracao}
                      onChange={(event) =>
                        setDsSugestaoInteracao(event.currentTarget.value)
                      }
                    />

                    <Button onClick={handleSendWhatsAppMessage}>
                      Enviar WhatsApp
                    </Button>
                  </>
                ) : null}
              </S.Sugestoes>
            ) : null}
          </S.Content>
        ) : null}
      </S.Card>

      <FiltroPesquisaModal
        isOpen={isOpenFiltroPesquisaModal}
        handleBuscarDados={handleFiltrarDadosPesquisa}
        filtro1={filtro1}
        filtro2={filtro2}
        filtro3={filtro3}
        filtro4={filtro4}
        filtro5={filtro5}
        onRequestClose={handleRequestCloseFiltroPesquisaModal}
      />

      <PDFViewerModal
        onCloseModal={handleClosePDFViewerModal}
        isOpen={openPdfViewer}
        casos={casos}
        pesquisa={{
          nome: pesquisa?.label as string,
          perguntaExtra: pesquisa?.perguntaExtra,
        }}
      />
    </PageContainer>
  );
}
