import styled from 'styled-components';

import { FiSmile, FiFrown, FiMeh } from 'react-icons/fi';

export const Container = styled.div`
  width: 100%;

  strong {
    color: var(--text);
  }
`;
export const Respostas = styled.div`
  margin-top: 20px;
  border-radius: 10px;

  padding: 20px;

  border: 1px solid var(--grey);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  height: auto;
  width: 100%;
  margin-bottom: 10px;

  align-items: center;

  margin-top: 10px;
`;

export const Icon = styled.div`
  height: 100%;
  width: 35px;
`;

export const Resposta = styled.div`
  height: auto;
  width: 100%;

  align-items: center;

  p {
    margin-left: 5px;
    color: #292c52;
    font-size: 1rem;

    text-align: justify;
  }
`;

export const IconSmile = styled(FiSmile)`
  color: var(--green);
`;

export const IconMeh = styled(FiMeh)`
  color: var(--yellow);
`;

export const IconFrown = styled(FiFrown)`
  color: var(--red);
`;

export const Title = styled.h2`
  color: var(--text);
`;
