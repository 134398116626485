import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('@Cooperando:token');
      localStorage.removeItem('@Cooperando:user');

      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default api;
