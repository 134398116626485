import styled from 'styled-components';

export const Header = styled.div`
  width: 60%;

  margin-bottom: 20px;

  align-items: center;
`;
export const Card = styled.div`
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;

  padding-bottom: 10px;
`;

export const Row = styled.div`
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  align-items: center;

  margin-bottom: 16px;

  margin-top: 16px;
  div {
    margin-top: 0;
    max-height: 56px;
  }

  button {
    margin-top: 0;
    max-height: 56px;
  }
`;

export const Content = styled.div`
  width: 100%;

  border-radius: 10px;
  background-color: var(--grey);
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  padding: 20px;
  margin-bottom: 5px;
  width: 100%;
`;
export const Table = styled.div`
  padding: 20px;
  width: 100%;
`;
