import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}
export default function getValidationError(err: ValidationError): Errors {
  const errosMessages: Errors = {};
  err.inner.forEach((error) => {
    errosMessages[error.path] = error.message;
  });

  return errosMessages;
}
