import React, { useCallback, useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';

import { DashboardCards } from '../../../components/DashboardCards';
import GraficoEvolucaoNps from '../../../components/GraficoEvolucaoNps';

import { useAuth } from '../../../hooks/auth';
import { useLoader } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';

import { FiltroPesquisaModal } from '../../../components/FiltroPesquisaModal';
import { InputSelect } from '../../../components/InputSelect';
import { PageContainer } from '../../../components/PageContainer';

import { IDashboardDataDto } from '../../../dtos/IDashboardDataDto';
import { IOptionSelectDto } from '../../../dtos/IOptionSelectDto';
import api from '../../../services/api';

import axios from 'axios';
import { Button } from '../../../components/Button';
import { DashboardCardRespostas } from '../../../components/DashboardCardRespostas';
import { DashboardPerguntaExtraCard } from '../../../components/DashboardPerguntaExtraCard';
import { GraficoNlp } from '../../../components/GraficoNlp';
import { NlpData } from '../../../dtos/NlpData';
import * as S from './styles';

export function Dashboard() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { showLoader, hideLoader } = useLoader();

  const [dashboardData, setDashboardData] = useState({} as IDashboardDataDto);

  const [usersOptions, setUsersOptions] = useState<IOptionSelectDto[]>([]);
  const [pesquisasOptions, setPesquisasOptions] = useState<IOptionSelectDto[]>(
    [],
  );
  const [unidadesOptions, setUnidadesOptions] = useState<IOptionSelectDto[]>(
    [],
  );
  const [filtroEvPesquisas, setFiltroEvPesq] = useState<IOptionSelectDto[]>([]);
  const [filtroEvUnidades, setFiltroEvUnidades] = useState<IOptionSelectDto[]>(
    [],
  );

  const [evNpsUnidades, setEvNpsUnidades] = useState<IOptionSelectDto[]>([]);
  const [evNpsPesquisas, setEvNpsPesquisas] = useState<IOptionSelectDto[]>([]);
  const [unidadeSelecionada, setUnidadeSelecionada] = useState<
    IOptionSelectDto
  >();
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<
    IOptionSelectDto
  >();
  const [pesquisaSelecionada, setPesquisaSelecionada] = useState<
    IOptionSelectDto
  >();

  const [openFiltroPesqModal, setOpenFiltroPesqModal] = useState(false);
  const [filtro1, setFiltro1] = useState<IOptionSelectDto[]>([]);
  const [filtro2, setFiltro2] = useState<IOptionSelectDto[]>([]);
  const [filtro3, setFiltro3] = useState<IOptionSelectDto[]>([]);
  const [filtro4, setFiltro4] = useState<IOptionSelectDto[]>([]);
  const [filtro5, setFiltro5] = useState<IOptionSelectDto[]>([]);

  const [nlpData, setNlpData] = useState<NlpData | null>();
  useEffect(() => {
    async function loadDashboard() {
      showLoader();
      const { users } = user;

      setUsersOptions(users);

      hideLoader();
    }
    loadDashboard();
  }, [user, showLoader, hideLoader]);

  useEffect(() => {
    setPesquisaSelecionada(pesquisasOptions[0]);
  }, [pesquisasOptions]);

  useEffect(() => {
    setUnidadeSelecionada(unidadesOptions[0]);
  }, [unidadesOptions]);

  const handleSelectUser = useCallback(
    async (value: any) => {
      showLoader();
      setUsuarioSelecionado(value);
      setNlpData(null);

      const response = await api.get(
        `/admin/dashboard/initialData?userId=${value.value}`,
      );

      const { unidades, pesquisas } = response.data;

      setUnidadesOptions(unidades);
      setPesquisasOptions(pesquisas);

      api
        .get(`/admin/dashboard?userId=${value.value}`)
        .then((response) => {
          setDashboardData({
            ...response.data,
            percentuaisPerguntaExtra: [],
            perguntaExtra: '',
          });
        })
        .catch((err) => {
          addToast({
            description: err.response.data.message,
            title: 'Erro!',
            type: 'error',
          });

          setDashboardData({} as IDashboardDataDto);
        })
        .finally(hideLoader);
    },
    [addToast, hideLoader, showLoader],
  );

  const handleSelectPesquisa = useCallback(
    async (pesquisa: any) => {
      showLoader();

      if (pesquisa.value !== '') {
        const responseFiltros = await api.get(
          `/admin/filtros?pesquisaId=${pesquisa.value}`,
        );

        setFiltro1(responseFiltros.data.filtros1);
        setFiltro2(responseFiltros.data.filtros2);
        setFiltro3(responseFiltros.data.filtros3);
        setFiltro4(responseFiltros.data.filtros4);
        setFiltro5(responseFiltros.data.filtros5);

        const response = await api.get(
          `/admin/dashboard?pesquisaId=${pesquisa?.value}`,
        );

        setDashboardData({
          ...response.data,
          perguntaExtra: pesquisa.perguntaExtra,
        });

        await axios
          .get(
            `https://tuaopiniao-nlp-service-007bf59f4d0c.herokuapp.com/get-survey-scores?surveyId=${pesquisa?.value}`,
          )
          .then((response) => setNlpData(response.data))
          .catch(() => setNlpData(null));
      } else {
        var response;
        if (unidadeSelecionada && unidadeSelecionada.value !== '') {
          response = await api.get(
            `/admin/dashboard?unidadeId=${unidadeSelecionada?.value}`,
          );

          setPesquisasOptions(response.data.pesquisasOptions);
        } else {
          response = await api.get(
            `/admin/dashboard?userId=${usuarioSelecionado?.value}`,
          );
        }

        setDashboardData({
          ...response.data,
          percentuaisPerguntaExtra: [],
          perguntaExtra: '',
        });

        setNlpData(null);
      }

      setPesquisaSelecionada(pesquisa);
      hideLoader();
    },
    [hideLoader, showLoader, usuarioSelecionado, unidadeSelecionada],
  );

  const handleSelectUnidade = useCallback(
    async (unidade: any) => {
      showLoader();

      setNlpData(null);
      setUnidadeSelecionada(unidade);

      if (unidade.value === '') {
        const response = await api.get(
          `/admin/dashboard?userId=${usuarioSelecionado?.value}`,
        );

        setDashboardData({
          ...response.data,
          percentuaisPerguntaExtra: [],
          perguntaExtra: '',
        });
        setPesquisasOptions(response.data.pesquisasOptions);

        hideLoader();

        return;
      }

      const response = await api.get(
        `/admin/dashboard?unidadeId=${unidade?.value}`,
      );

      setDashboardData({
        ...response.data,
        percentuaisPerguntaExtra: [],
        perguntaExtra: '',
      });
      setPesquisasOptions(response.data.pesquisasOptions);
      hideLoader();
    },
    [hideLoader, showLoader, usuarioSelecionado],
  );

  const handleBuscarDadosEvolucaoNpsUnidades = async () => {
    showLoader();

    if (!usuarioSelecionado) {
      hideLoader();

      return;
    }

    const response = await api.get(
      `admin/dashboard/evolucaoNps/unidades?userId=${usuarioSelecionado?.value}`,
    );

    const { evolucaoNpsUnidades } = response.data;
    setFiltroEvUnidades(evolucaoNpsUnidades);

    hideLoader();
  };

  const handleBuscarDadosEvolucaoNpsPesquisas = async () => {
    showLoader();

    if (!usuarioSelecionado) {
      hideLoader();

      return;
    }

    const response = await api.get(
      `admin/dashboard/evolucaoNps/pesquisas?userId=${usuarioSelecionado?.value}`,
    );
    const { evolucaoNpsPesquisas } = response.data;

    setFiltroEvPesq(evolucaoNpsPesquisas);

    hideLoader();
  };

  const handleBuscarDados = useCallback(
    async (filtros: Array<string | undefined>) => {
      if (!pesquisaSelecionada) {
        return;
      }

      const response = await api.get('/admin/dashboard/filtros?', {
        params: {
          pesquisaId: pesquisaSelecionada.value,
          filtro1: filtros[0],
          filtro2: filtros[1],
          filtro3: filtros[2],
          filtro4: filtros[3],
          filtro5: filtros[4],
        },
      });
      setDashboardData({
        ...response.data,
        perguntaExtra: pesquisaSelecionada.perguntaExtra,
      });
    },
    [pesquisaSelecionada],
  );

  const handleCloseFiltroPesquisaModal = () => {
    setOpenFiltroPesqModal(!openFiltroPesqModal);
  };

  return (
    <PageContainer title="Dashboard">
      <S.Filtro>
        <InputSelect
          options={usersOptions}
          placeholder="Selecione um usuário"
          onChange={(value) => handleSelectUser(value)}
          value={usuarioSelecionado}
        />
        <InputSelect
          options={unidadesOptions}
          placeholder="Selecione uma unidade"
          onChange={(value) => handleSelectUnidade(value)}
          value={unidadeSelecionada}
        />

        <InputSelect
          options={pesquisasOptions}
          placeholder="Selecione uma pesquisa"
          onChange={(value) => handleSelectPesquisa(value)}
          value={pesquisaSelecionada}
        />

        {(filtro1.length > 0 ||
          filtro4.length > 0 ||
          filtro2.length > 0 ||
          filtro3.length > 0 ||
          filtro5.length > 0) && (
            <Button onClick={() => setOpenFiltroPesqModal(true)}>
              Filtros da pesquisa
            </Button>
          )}
      </S.Filtro>

      <DashboardCards
        percentDetratores={dashboardData.percentDetratores}
        percentNeutros={dashboardData.percentNeutros}
        percentPromotores={dashboardData.percentPromotores}
        quantidadeDetratores={dashboardData.quantidadeDetratores}
        quantidadePromotores={dashboardData.quantidadePromotores}
        quantidadeNeutros={dashboardData.quantidadeNeutros}
        quantidadeResposta={dashboardData.quantidadeResposta}
        percentEngajameto={
          (dashboardData.quantidadeResposta / dashboardData.totalRead) * 100
        }
        totalRead={dashboardData.totalRead}
      />

      <S.Body>
        <DashboardCardRespostas
          segundaPergunta={pesquisaSelecionada?.segundaPergunta}
          respostasRuins={dashboardData.respostasRuins}
          respostasNeutras={dashboardData.respostasNeutras}
          respostasBoas={dashboardData.respostasBoas}
        />

        <DashboardPerguntaExtraCard
          perguntaExtra={dashboardData.perguntaExtra}
          percentuaisPerguntaExtra={dashboardData.percentuaisPerguntaExtra}
        />
      </S.Body>

      {nlpData &&
        nlpData.feelingsScores &&
        nlpData.feelingsScores.length !== 0 ? (
        <S.Body>
          <div>
            <S.Title>Análise geral de sentimentos</S.Title>

            <GraficoNlp feelingsScores={nlpData.feelingsScores} />
          </div>
        </S.Body>
      ) : null}

      {pesquisaSelecionada && (pesquisaSelecionada.wordCloudPromotoresUrl || pesquisaSelecionada.wordCloudNeutrosUrl || pesquisaSelecionada.wordCloudDetratoresUrl) && (
        <div>
          <S.Title>Direcionadores da NPS</S.Title>

          {
            pesquisaSelecionada.wordCloudPromotoresUrl && (<img
              src={pesquisaSelecionada.wordCloudPromotoresUrl}
              alt="Promotores"
              style={{
                width: "33%",
              }}
            />)
          }

          {
            pesquisaSelecionada.wordCloudNeutrosUrl && (
              <img
                src={pesquisaSelecionada.wordCloudNeutrosUrl}
                alt="Neutros"
                style={{
                  width: "33%",
                }}
              />
            )
          }


          {
            pesquisaSelecionada.wordCloudDetratoresUrl && (
              <img
                src={pesquisaSelecionada.wordCloudDetratoresUrl}
                alt="Detratores"
                style={{
                  width: "33%",
                }}
              />
            )
          }



        </div>
      )}


      <S.EvolucaoNps>
        <S.Row>
          <S.Title>Evolução das pesquisas</S.Title>
          <S.DivButton>
            <Button onClick={() => handleBuscarDadosEvolucaoNpsPesquisas()}>
              Buscar dados
            </Button>
          </S.DivButton>
        </S.Row>

        <S.CardNps>
          <S.GraficoNps>
            <MultiSelect
              options={filtroEvPesquisas.filter((item) => item.value !== '')}
              value={evNpsPesquisas}
              onChange={setEvNpsPesquisas}
              labelledBy={'aria-labelledby'}
              overrideStrings={{
                selectSomeItems: 'Filtrar...',
                allItemsAreSelected: 'Todas as pesquisas selecionadas.',
                selectAll: 'Selecionar todas...',
                search: 'Procurar',
                clearSearch: 'Limpar',
              }}
            />

            <GraficoEvolucaoNps
              categories={evNpsPesquisas.map((item) => item.label)}
              dados={evNpsPesquisas.map((item) =>
                parseInt(item.nps ? item.nps : '0'),
              )}
            />
          </S.GraficoNps>
        </S.CardNps>

        <S.CardNps>
          <S.Row>
            <S.Title>Evolução das unidades</S.Title>

            <S.DivButton>
              <Button onClick={() => handleBuscarDadosEvolucaoNpsUnidades()}>
                Buscar dados
              </Button>
            </S.DivButton>
          </S.Row>
          <S.GraficoNps>
            <MultiSelect
              options={filtroEvUnidades.filter((item) => item.nps !== '')}
              value={evNpsUnidades}
              onChange={setEvNpsUnidades}
              labelledBy={'aria-labelledby'}
              overrideStrings={{
                selectSomeItems: 'Filtrar...',
                allItemsAreSelected: 'Todas as unidades selecionadas.',
                selectAll: 'Selecionar todas...',
                search: 'Procurar',
                clearSearch: 'Limpar',
              }}
            />
            <GraficoEvolucaoNps
              categories={evNpsUnidades.map((item) => item.label)}
              dados={evNpsUnidades.map((item) =>
                parseInt(item.nps ? item.nps : '0'),
              )}
            />
          </S.GraficoNps>
        </S.CardNps>
      </S.EvolucaoNps>

      <FiltroPesquisaModal
        isOpen={openFiltroPesqModal}
        handleBuscarDados={handleBuscarDados}
        filtro1={filtro1}
        filtro2={filtro2}
        filtro3={filtro3}
        filtro4={filtro4}
        filtro5={filtro5}
        onRequestClose={handleCloseFiltroPesquisaModal}
      />
    </PageContainer>
  );
}
