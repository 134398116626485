import React, { useState } from 'react';
import { scaleRotate as Menu } from 'react-burger-menu';
import { Switch } from 'react-router-dom';

import {
  FiPaperclip,
  FiBarChart2,
  FiFileText,
  FiLogOut,
  FiSearch,
  FiFilePlus,
  FiUsers,
  FiPlus,
  FiTag,
  FiDatabase,
} from 'react-icons/fi';

import logo from '../../assets/logoBranca.png';
import Route from '../../routes/Route';
import { Dashboard } from '../../pages/Admin/Dashboard';
import Unidades from '../../pages/Admin/Unidades';
import { Pesquisas } from '../../pages/Admin/Pesquisas';
import { Templates } from '../../pages/Admin/Templates';
import { Usuarios } from '../../pages/Admin/Usuarios';
import { Container, Content, Button, NavLinkStyled, Imagem } from './styles';
import { DadosPesquisa } from '../../pages/Admin/DadosPesquisa';
import { Casos } from '../../pages/Admin/Casos';
import { Filtros } from '../../pages/Admin/Filtros/index';

import { useAuth } from '../../hooks/auth';

import { Planos } from '../../pages/Admin/Planos';
import { Bullboard } from '../../pages/Admin/Bullboard';

const AdminSidebar: React.FC = () => {
  const { signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const routes = [
    { key: 'dash', label: 'Dashboard', path: '/admin/dashboard' },
    { key: 'pes', label: 'Pesquisas', path: '/admin/pesquisas' },
    { key: 'nps', label: 'NPS', path: '/admin/nps' },
    { key: 'fil', label: 'Filtros', path: '/admin/filtros' },
    { key: 'und', label: 'Unidades', path: '/admin/unidades' },
    { key: 'tem', label: 'Templates', path: '/admin/templates' },
    { key: 'usr', label: 'Usuários', path: '/admin/usuarios' },
    { key: 'pla', label: 'Planos', path: '/admin/planos' },
    { key: 'dpl', label: 'Logs da Pesquisa', path: '/admin/pesquisa/logs' },
    { key: 'blb', label: 'Bull-board', path: '/admin/bull-board' },
  ];

  return (
    <Container id="outer-container">
      <Menu
        isOpen={isOpen}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      >
        <Imagem>
          <img src={logo} alt="Cooperando" />
        </Imagem>

        {routes.map(({ key, label, path }) => (
          <NavLinkStyled
            key={key}
            to={path}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {key === 'nps' ? (
                <FiPaperclip size={20} />
              ) : key === 'dash' ? (
                <FiBarChart2 size={20} />
              ) : key === 'pes' ? (
                <FiSearch size={20} />
              ) : key === 'und' ? (
                <FiFileText size={20} />
              ) : key === 'tem' ? (
                <FiFilePlus size={20} />
              ) : key === 'usr' ? (
                <FiUsers size={20} />
              ) : key === 'fil' ? (
                <FiFilePlus size={20} />
              ) : key === 'dpl' ? (
                <FiPlus size={20} />
              ) : key === 'pla' ? (
                <FiTag size={20} />
              ) : key === 'blb' ? (
                <FiDatabase size={20} />
              ) : null}
              <p>{label}</p>
            </Button>
          </NavLinkStyled>
        ))}

        <Button onClick={() => signOut()}>
          <FiLogOut size={20} />
          <p>Sair</p>
        </Button>
      </Menu>

      <Content id="page-wrap">
        <Switch>
          <Route
            path="/admin/dashboard"
            exact
            component={Dashboard}
            isPrivate
          />
          <Route path="/admin/nps" exact component={Casos} isPrivate />
          <Route
            path="/admin/templates"
            exact
            component={Templates}
            isPrivate
          />
          <Route
            path="/admin/pesquisas"
            exact
            component={Pesquisas}
            isPrivate
          />
          <Route path="/admin/unidades" exact component={Unidades} isPrivate />
          <Route path="/admin/usuarios" exact component={Usuarios} isPrivate />
          <Route path="/admin/filtros" exact component={Filtros} isPrivate />
          <Route path="/admin/planos" exact component={Planos} isPrivate />

          <Route
            path="/admin/pesquisa/logs"
            exact
            component={DadosPesquisa}
            isPrivate
          />
          <Route
            path="/admin/bull-board"
            exact
            component={Bullboard}
            isPrivate
          />
        </Switch>
      </Content>
    </Container>
  );
};

export default AdminSidebar;
