import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// import { Container } from './styles';

function Charts({ value }) {
  const [chaves, setChaves] = useState([]);
  const [valores, setValores] = useState([]);

  useEffect(() => {
    var chaves1 = [];
    var valores1 = [];
    value.forEach((sugestao) => {
      chaves1.push(sugestao.titulo);
      valores1.push(sugestao.valor);
    });

    setChaves(chaves1);
    setValores(valores1);
  }, [value]);

  const series = [
    {
      name: 'Valores',
      data: valores,
    },
  ];
  const options = {
    chart: {
      height: 200,
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Number.parseInt(val).toPrecision(2) + '%';
      },
      offsetY: -20,
      style: {
        fontSize: '16px',
        colors: ['#304758'],
      },
    },
    xaxis: {
      categories: chaves,
      labels: {
        show: true,
        rotateAlways: false,
        hideOverlappingLabels: false,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 300,
        style: {
          colors: [],
          fontSize: '16px',
          fontFamily: 'Roboto Slab, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
      },

      tickAmount: undefined,
      tickPlacement: 'between',
      min: undefined,
      max: undefined,
      range: undefined,
      floating: false,
      position: 'bottom',
    },

    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return Number.parseInt(val).toPrecision(2) + '%';
        },
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={250}
      />
    </>
  );
}

export default Charts;
