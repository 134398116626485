import styled, { css } from 'styled-components';

export const Container = styled.div<ColorProps>`
  display: flex;

  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  height: 150px;

  border-radius: 10px;
  padding: 10px;

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 150px;

  align-items: center;

  flex-direction: row;
`;

export const Footer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 40px;
  width: 100%;

  margin-top: 5px;

  background: var(--background);
  border-radius: 20px;
`;

export const Valores = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

interface ColorProps {
  color: string;
}

export const Titulo = styled.h1`
  font-size: 1.5rem;
  color: var(--white);
`;

export const Percentual = styled.strong`
  color: var(--white);
  font-size: 1.85rem;
`;

export const Respostas = styled.p`
  color: var(--text);

  font-size: 1rem;
`;
