import React from 'react';
import { Title } from './styles';

interface ModalTitleProps {
  title: string;
}

export function ModalTitle({ title }: ModalTitleProps) {
  return <Title>{title}</Title>;
}
