import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { Container } from './styles';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  autoComplete?: string;
}

export function TextArea({ name, ...rest }: TextAreaProps) {
  const inputElementRef = useRef<HTMLTextAreaElement>(null);

  const { registerField, defaultValue = '', fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputElementRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <textarea defaultValue={defaultValue} ref={inputElementRef} {...rest} />
    </Container>
  );
}

export default TextArea;
