import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { InputSelect } from '../../../components/InputSelect';
import { PageContainer } from '../../../components/PageContainer';
import { IDisparoPesquisaLogs } from '../../../dtos/IDisparoPesquisaLogsDto';
import { IOptionSelectDto } from '../../../dtos/IOptionSelectDto';
import { useAuth } from '../../../hooks/auth';
import { useLoader } from '../../../hooks/loading';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import * as S from './styles';

export function DadosPesquisa() {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { showLoader, hideLoader } = useLoader();

  const [disparoPesquisaLogs, setDisparoPesquisaLogs] = useState<
    IDisparoPesquisaLogs
  >();
  const [pesquisasOptions, setPesquisasOptions] = useState<
    Array<IOptionSelectDto>
  >([]);

  const [usersOptions, setUsersOptions] = useState<IOptionSelectDto[]>([]);
  const [pesquisaSelected, setPesquisaSelected] = useState<IOptionSelectDto>(
    {} as IOptionSelectDto,
  );

  const [totalRespostas, setTotalRespostas] = useState(0);

  useEffect(() => {
    setUsersOptions(user.users);
  }, [user.users]);

  function handleSelectUser(value: any) {
    showLoader();

    setPesquisaSelected({
      label: 'Selecione uma pesquisa...',
      value: '',
    });

    api
      .get(`/admin/pesquisas?userId=${value.value}`)
      .then((response) => {
        if (response.data) {
          setPesquisasOptions(
            response.data.map((pesquisa: any) => ({
              value: pesquisa.id,
              label: pesquisa.nome,
            })),
          );
        }
      })
      .catch((err) => {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: err.response.data.message,
        });
      })
      .finally(hideLoader);
  }

  function handleSelectPesquisa(pesquisa: IOptionSelectDto) {
    showLoader();
    api
      .get(`/pesquisas/logs/${pesquisa.value}`)
      .then((response) => {
        setDisparoPesquisaLogs(response.data.logs);
        setTotalRespostas(response.data.totalDeCasos);
      })
      .finally(hideLoader);

    setPesquisaSelected(pesquisa);
  }

  function handleAtualizarDados() {
    showLoader();

    api
      .post(`/pesquisas/logs/${pesquisaSelected.value}`)
      .then(() => {
        api
          .get(`/pesquisas/logs/${pesquisaSelected.value}`)
          .then((response) => {
            setDisparoPesquisaLogs(response.data.logs);
            setTotalRespostas(response.data.totalDeCasos);
          });
      })
      .finally(hideLoader);
  }

  return (
    <PageContainer>
      <S.Header>
        <h2>Selecione o Usuário</h2>
        <InputSelect
          name="user"
          placeholder="Selecione um usuário"
          options={usersOptions}
          onChange={(value) => handleSelectUser(value)}
        />
        <h2>Selecione a pesquisa</h2>
        <InputSelect
          name="pesquisa"
          placeholder="Selecione uma pesquisa"
          onChange={(value) => handleSelectPesquisa(value as IOptionSelectDto)}
          options={pesquisasOptions}
          value={pesquisaSelected}
        />
      </S.Header>
      {disparoPesquisaLogs && (
        <>
          <S.DataGerado>
            Dados gerados em:{' '}
            {new Date(disparoPesquisaLogs.created_at).toLocaleDateString()}
          </S.DataGerado>

          <S.Row>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que estavam na sua lista.
              </span>
              <strong>Total de contatos:</strong>
              <p>{disparoPesquisaLogs.totalContatos}</p>
            </S.Card>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que estavam em carência na hora do
                envio.
              </span>
              <strong>Total de carência:</strong>
              <p>{disparoPesquisaLogs.totalEmCarencia}</p>
            </S.Card>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Quantidade de contatos na lista - quantidade contatos em
                carência.
              </span>
              <strong>Total de disparado:</strong>
              <p>{disparoPesquisaLogs.totalEnviados}</p>
            </S.Card>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Quantidade de mensagens disparadas.
              </span>
              <strong>Total de enviados:</strong>
              <p>{disparoPesquisaLogs.totalSent}</p>
            </S.Card>
          </S.Row>
          <S.Row>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que receberam a mensagem inicial
                enviada por nós.
              </span>
              <strong>Total de entregues:</strong>
              <p>{disparoPesquisaLogs.totalDelivered}</p>
            </S.Card>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos que leram a mensagem inicial enviada
                por nós.
              </span>

              <strong>Total de lido:</strong>
              <p>{disparoPesquisaLogs.totalRead}</p>
            </S.Card>
            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos não receberam a mensagem inicial
                enviada por nós.
              </span>
              <strong>Total de erros:</strong>
              <p>{disparoPesquisaLogs.totalErrors}</p>
            </S.Card>

            <S.Card className="tooltip">
              <span className="tooltiptext">
                Esse é o número de contatos não receberam a mensagem inicial
                enviada por nós por problema número de telefone.
              </span>
              <strong>Total de não entregues:</strong>
              <p>{disparoPesquisaLogs.totalUndelivered}</p>
            </S.Card>

            <S.Card className="tooltip">
              <span className="tooltiptext">
                Total de respostas da pesquisa.
              </span>

              <strong>Total de respostas:</strong>
              <p>{totalRespostas}</p>
            </S.Card>

            {disparoPesquisaLogs.totalRead && (
              <S.Card className="tooltip">
                <span className="tooltiptext">
                  Taxa de resposta da pesquisa.
                </span>

                <strong>Taxa de resposta:</strong>
                <p>
                  {parseFloat(
                    (
                      (totalRespostas / disparoPesquisaLogs.totalRead) *
                      100
                    ).toString(),
                  ).toFixed(2) + '%'}
                </p>
              </S.Card>
            )}
          </S.Row>
          <Button onClick={handleAtualizarDados}>Atualizar dados</Button>
        </>
      )}
    </PageContainer>
  );
}
